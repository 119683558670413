import React, { useEffect, useState, useCallback } from "react";
import "../css/notifications.scss";
import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";
import { getNotifications } from "../provider/NotificationServices";
import moment from "moment";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorFetch, setErrorFetch] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [emailInput, setEmailInput] = useState("");
  const [values, setValues] = useState({
    type: "success",
    email: null,
    title: null,
    page: 1,
  });

  const updateNotifications = useCallback(
    async (type, email = null, title = null, page) => {
      setNotifications([]);
      window.scrollTo(0, 0);
      setLoading(true);
      let result = await getNotifications(type, email, title, page);
      if (result.success === false) {
        setLoading(false);
        setErrorFetch(true);
      } else if (result.success === true && result.data.no_count === 0) {
        setLoading(false);
        setErrorFetch(false);
      } else if (result.success === true && result.data.no_count > 0) {
        setErrorFetch(false);
        let count = result.data.no_count;
        let pages = Math.ceil(count / 20);
        const notificationsDisplay = result.data.result.map(notification => {
          return {
            id: notification.no_id,
            date: moment(notification.no_date).format("DD/MM/YYYY"),
            time: moment(notification.no_date).format("hh:mm:ss"),
            name:
              notification.no_name && notification.no_lastname
                ? notification.no_name + " " + notification.no_lastname
                : "",
            email:
              notification.no_email === "null" || !notification.no_email
                ? ""
                : notification.no_email,
            title: notification.no_title,
            message: notification.no_message,
          };
        });

        setLoading(false);
        setNotifications(notificationsDisplay);

        updatePagination(page, pages);
      }
    },
    [],
  );

  useEffect(() => {
    updateNotifications(values.type, values.email, values.title, 1);
  }, [updateNotifications, values.email, values.title, values.type]);

  const updatePagination = (page, pages) => {
    let max = 10;
    let media = max / 2;
    let iteration = 1;
    if (page > media) {
      iteration = page - media + 1;
    }
    if (pages <= max) {
      iteration = 1;
      max = pages;
    } else {
      max = iteration + max - 1;
    }

    let paginationTemp = [];
    for (let i = iteration; i <= max; i++) {
      paginationTemp.push(i);
    }

    setPagination(paginationTemp);
  };

  const handleChangeTitle = e => {
    const { value } = e.target;
    setValues(prevState => ({
      ...prevState,
      title: value || null,
    }));
  };

  const handleChangeEmail = e => {
    e.preventDefault();
    setValues(prevState => ({
      ...prevState,
      email: emailInput || null,
    }));
  };

  const handleChangeType = type => {
    setValues(prevState => ({
      ...prevState,
      type: type,
    }));
  };

  return (
    <div>
      <Header />
      <NavigationTitle
        title={"Notificaciones"}
        icon={"../images/icons/xiclos-icon-red@2x.png"}
      />
      <div className="container mb-5 notifications">
        <div className="align-items-center justify-content-center h-100 padding-top">
          <div className="row align-items-center justify-content-center h-100">
            <div style={{ width: "100%" }}>
              <div id="user-card" className="sign-user_card">
                <div
                  className="row d-flex justify-content-between align-items-center"
                  style={{ marginBottom: "1.5em" }}>
                  <div className="sign-up-header col px-0">
                    <p
                      className="mb-0"
                      style={{
                        fontFamily: "Helvetica Neue Bold",
                        color: "#343433",
                        fontSize: "1em",
                      }}>
                      Notificaciones mas recientes
                    </p>
                  </div>
                  <div className="col text-right px-0  d-flex justify-content-end align-items-center">
                    <form onSubmit={handleChangeEmail}>
                      <div className="notifications-input mx-2 d-flex align-items-center">
                        <select
                          name="select"
                          className="notifications-select"
                          id="notifications-select"
                          onChange={handleChangeTitle}>
                          <option value="" defaultValue>
                            Ninguno
                          </option>
                          <option value="Alta de usuario">
                            Alta de usuario
                          </option>
                          <option value="Suscripción">Suscripción</option>
                          <option value="Pagos">Pagos</option>
                        </select>

                        <input
                          value={emailInput}
                          onChange={e => setEmailInput(e.target.value)}
                          type="text"
                          id="notifications-input"
                          className="notifications-input mx-2"
                          placeholder="Email"
                        />
                        <button
                          type="submit"
                          className="btn btn-hover"
                          id="notifications-filter">
                          Filtrar
                        </button>
                      </div>
                    </form>
                    <div className="notifications-switch d-flex justify-content-end align-items-center ml-2">
                      <div
                        className={`notifications-option ${
                          values.type === "success"
                            ? "notifications-option-active"
                            : ""
                        }`}
                        onClick={() => handleChangeType("success")}
                        id="notification-success">
                        <img
                          src="../images/icons/success-icon.svg"
                          width="20"
                          alt=""
                        />
                      </div>
                      <div
                        className={`notifications-option ${
                          values.type === "error"
                            ? "notifications-option-active"
                            : ""
                        }`}
                        onClick={() => handleChangeType("error")}
                        id="notification-error">
                        <img
                          src="../images/icons/error-icon.svg"
                          width="20"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Hora</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Email</th>
                      <th scope="col">Título</th>
                      <th scope="col">Descripción</th>
                    </tr>
                  </thead>
                  <tbody id="notifications-tbody">
                    {loading && (
                      <tr>
                        <th colSpan="6" className="text-center">
                          Cargando..
                        </th>
                      </tr>
                    )}

                    {errorFetch && (
                      <tr>
                        <td colSpan="6" className="text-center py-5">
                          ERROR DE SISTEMA
                        </td>
                      </tr>
                    )}
                    {notifications.length > 0 ? (
                      notifications.map(notification => (
                        <tr key={notification.id}>
                          <th scope="row">{notification.date}</th>
                          <th scope="row">{notification.time}</th>
                          <td>{notification.name}</td>
                          <td>{notification.email}</td>
                          <td>{notification.title}</td>
                          <td>{notification.message}</td>
                        </tr>
                      ))
                    ) : !loading ? (
                      <tr>
                        <td colSpan="6" className="text-center py-5">
                          No hay notificaciones con ese filtro
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                <nav aria-label="Pagination">
                  <ul className="pagination pagination-md justify-content-center">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      id="pagination-prev">
                      <button
                        className={`page-link page-prev ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        aria-label="Previo"
                        onClick={() => {
                          if (currentPage > 1) {
                            setCurrentPage(currentPage - 1);
                            updateNotifications(
                              values.type,
                              values.email,
                              values.title,
                              currentPage - 1,
                            );
                          }
                        }}>
                        &laquo;
                      </button>
                    </li>

                    {pagination.length > 0 &&
                      pagination.map(page => (
                        <li
                          key={page}
                          className={`${
                            currentPage === page ? "active" : ""
                          } page-item`}>
                          <button
                            onClick={() => {
                              setCurrentPage(page);
                              updateNotifications(
                                values.type,
                                values.email,
                                values.title,
                                page,
                              );
                            }}
                            className={`${
                              currentPage === page
                                ? "page-link active"
                                : "page-link"
                            } page-link`}>
                            {page}
                          </button>
                        </li>
                      ))}

                    <li className="page-item" id="pagination-next">
                      <button
                        className="page-link page-link page-next"
                        aria-label="Siguiente"
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                          updateNotifications(
                            values.type,
                            values.email,
                            values.title,
                            currentPage + 1,
                          );
                        }}>
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Notifications;
