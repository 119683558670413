import firebase from "firebase";
import "firebase/storage";
import { PAYMENT_ACCEPTED, STATUS_USER_INACTIVE } from "../common/Constant";

let firebaseInstance: firebase.app.App | null = null;
let unsubscribeSnapshotPayment: (() => void) | null = null;

const getFirebaseInstance: any = () => {
  if (!firebaseInstance) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    if (firebase.apps[0]) {
      firebaseInstance = firebase.app();
    } else {
      firebaseInstance = firebase.initializeApp(firebaseConfig);
    }
  }
  return firebaseInstance;
};

const getToken = () => {
  return getFirebaseInstance().auth().currentUser?.getIdToken(true);
};

const signOut = () => {
  return getFirebaseInstance().auth().signOut();
};

const onAuthStateChanged = (
  callback: firebase.Observer<any, Error> | ((a: firebase.User | null) => any),
) => {
  getFirebaseInstance().auth().onAuthStateChanged(callback);
};

const currentUser = () => {
  return getFirebaseInstance().auth().currentUser;
};

const onSnapshotPayment = (
  user: { us_email: any; us_suscription_status: string },
  onChange: (arg0: firebase.firestore.DocumentData | undefined) => void,
) => {
  let email = user.us_email;
  let status = user.us_suscription_status;
  if (user.us_suscription_status !== "free") {
    getFirebaseInstance()
      .firestore()
      .collection("users")
      .doc(email)
      .get()
      .then((snap: { data: () => any }) => {
        const pu = snap.data();
        unsubscribeSnapshotPayment = getFirebaseInstance()
          .firestore()
          .collection("users")
          .doc(email)
          .collection("payments")
          .doc(pu?.current)
          .onSnapshot((snapshot: { data: () => any }) => {
            const payment = snapshot.data();
            onChange(payment);
            if (
              payment?.payment_state === PAYMENT_ACCEPTED &&
              status !== STATUS_USER_INACTIVE
            ) {
              unsubscribeSnapshotPayment && unsubscribeSnapshotPayment();
              unsubscribeSnapshotPayment = null;
            }
          });
      });
  }
};

const unsubscribePayment = () => {
  unsubscribeSnapshotPayment && unsubscribeSnapshotPayment();
};

const updatePassword = async (oldPassword: any, newPassword: string) => {
  let user = getFirebaseInstance().auth().currentUser;
  if (user) {
    let provider = getFirebaseInstance()?.firebase_?.auth.EmailAuthProvider;
    let cred = provider.credential(user.email, oldPassword);
    try {
      await user.reauthenticateWithCredential(cred);
      try {
        return await user.updatePassword(newPassword);
      } catch (err) {
        return err;
      }
    } catch (err) {
      return err;
    }
  }
};

const getStorageRef = async () => {
  return await getFirebaseInstance().storage().ref();
};

export default {
  signOut: signOut,
  onAuthStateChanged: onAuthStateChanged,
  onSnapshotPayment: onSnapshotPayment,
  unsubscribePayment: unsubscribePayment,
  getToken: getToken,
  currentUser: currentUser,
  updatePassword: updatePassword,
  getStorageRef: getStorageRef,
};
