import React from "react";
import Loader from "./Loader";
import PosterPull from "./PosterPull";

const renderPoster = (
  movie,
  idx,
  slId,
  handleModal,
  freeMode,
  noLista,
  customClass,
) => {
  return (
    <PosterPull
      key={`${slId}-${idx}`}
      item={movie}
      id={slId}
      handleModal={handleModal}
      freeMode={freeMode}
      noLista={noLista}
      customClass={customClass}
    />
  );
};

export default function SliderPull({
  id,
  title,
  slider,
  handleModal,
  freeMode,
  noLista,
  customClass,
}) {
  return (
    <section id={`${id}`}>
      <div className="container-fluid wrapper-movie-interest pb-5">
        <div className="row">
          <div className="col-12 px-0">
            <h4 className="text-sm-center text-md-left pl-md-5 my-4 my-md-5 vortex-slides-title">
              {title}
            </h4>

            <div className="row d-block">
              <div
                id="vortex-recommend-ul"
                className="col-12 d-flex flex-wrap vortex-slides vortex-slides-pull px-md-3 mx-auto">
                {!(slider.movies && slider.movies.length > 0) && (
                  <div className="divLoading">
                    <Loader show={true} />
                  </div>
                )}
                {slider.movies &&
                  slider.movies.length > 0 &&
                  slider.movies.map((el, i) =>
                    renderPoster(
                      el,
                      i,
                      id,
                      handleModal,
                      freeMode,
                      noLista,
                      customClass,
                    ),
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
