import {
  ACT_SLIDER_CHANGE,
  ACT_BANNER_CHANGE,
  ACT_SLIDER_INIT,
  ACT_SLIDER_COUNT,
  ACT_SLIDER_DIRECTORS,
  ACT_SLIDER_UPDATE,
  ACT_IS_NOT_LOADING_APP,
} from "./ActionTypes";
import {
  getCountAllSliders,
  getSliders,
  getMoviesBySliderId,
  getAllSliders,
} from "../provider/SliderServices";
import { getBanners } from "../provider/BannerServices";
import { getDirectors } from "../provider/DirectorService";
import { validateBanners, validateSliders } from "../common/Util";
import { API } from "../provider/APIConnector";
import { HEADERS } from "../provider/APIConnector";

const fillMovies = slider => {
  const countMovies = slider.count_movies;
  const emptyMovies = new Array(countMovies - slider.movies.length);
  emptyMovies.fill({});
  const movies = slider.movies.concat(emptyMovies);
  return { ...slider, movies: movies };
};

const checkHomeInit = async localSliders => {
  let user = JSON.parse(localStorage.getItem("U"));
  let subscriptions = null;
  if (user && user?.subscriptions && user?.subscriptions?.length > 0) {
    subscriptions = user.subscriptions.map(sub => ({ su_id: sub?.su_id }));
  }
  const data = await fetch(`${API}/v2/home/check`, {
    method: "POST",
    headers: { ...HEADERS(), "Content-Type": "application/json" },
    body: JSON.stringify({
      sliders: localSliders,
      subscriptions,
    }),
  });
  return data.json(data);
};

export async function initHomeAction(dispatch, getState) {
  //INFO: Dispatch y getState from redux thunk
  //TODO: Arreglar la funcionalidad de los sliders que se guarden en localstorage y agregar aca todo lo relacionado a
  //carga de mi lista y continuar viendo
  let localSliders = JSON.parse(localStorage.getItem("sliders"));
  const localSlidersCompressed =
    localSliders &&
    localSliders.map(slider => ({
      sl_id: slider.sl_id,
      sl_order: slider.sl_order,
      count_movies: slider.count_movies,
      movies: slider.movies.map(mov => ({ mo_id: mov.mo_id })),
    }));

  try {
    let checkHomeData;
    try {
      checkHomeData = await checkHomeInit(
        localSlidersCompressed,
        getState().usr.user,
      );
    } catch (errorCheck) {
      console.error("ERROR CHECK", errorCheck);
    }
    const resultGetCountAllSliders = await getCountAllSliders();
    dispatch({
      type: ACT_SLIDER_COUNT,
      countSliders: resultGetCountAllSliders.data[0].count_sliders,
    });
    if (localSliders && localSliders?.length > 0) {
      if (
        // localSliders?.length !== resultGetCountAllSliders.data[0].count_sliders
        checkHomeData &&
        checkHomeData?.data?.sliders
      ) {
        const resultAllSliders = await getAllSliders(
          0,
          9999,
          { videoclub: 0 },
          9999,
        );
        dispatch({
          type: ACT_SLIDER_INIT,
          sliders: resultAllSliders.data.map(fillMovies),
        });
        localStorage.setItem(
          "sliders",
          JSON.stringify(resultAllSliders.data.map(fillMovies)),
        );
      } else {
        dispatch({
          type: ACT_SLIDER_INIT,
          sliders: localSliders,
        });
      }
    } else {
      const resultAllSliders = await getAllSliders(
        0,
        9999,
        { videoclub: 0 },
        9999,
      );
      dispatch({
        type: ACT_SLIDER_INIT,
        sliders: resultAllSliders.data.map(fillMovies),
      });
      localStorage.setItem(
        "sliders",
        JSON.stringify(resultAllSliders.data.map(fillMovies)),
      );
    }
  } catch (error) {
    console.error("ERROR SLIDERS", error);
  }
  try {
    let localBanners = localStorage.getItem("banners");
    if (localBanners && localBanners !== "null") {
      localBanners = JSON.parse(localBanners);
      if (localBanners.length > 0) {
        dispatch({
          type: ACT_BANNER_CHANGE,
          props: {
            banners: localBanners,
          },
        });
        const resultGetBanners = await getBanners();
        let newBanners = validateBanners(localBanners, resultGetBanners);
        if (newBanners) {
          dispatch({
            type: ACT_BANNER_CHANGE,
            props: {
              banners: resultGetBanners,
            },
          });
          localStorage.setItem("banners", JSON.stringify(resultGetBanners));
        }
      } else {
        const resultGetBanners = await getBanners();
        dispatch({
          type: ACT_BANNER_CHANGE,
          props: {
            banners: resultGetBanners,
          },
        });
        localStorage.setItem("banners", JSON.stringify(resultGetBanners));
      }
    } else {
      const resultGetBanners = await getBanners();
      dispatch({
        type: ACT_BANNER_CHANGE,
        props: {
          banners: resultGetBanners,
        },
      });
      localStorage.setItem("banners", JSON.stringify(resultGetBanners));
    }
  } catch (errorBanner) {
    console.error("ERROR BANNERS");
  }

  try {
    const resultAllDirectors = await getDirectors();
    dispatch({
      type: ACT_SLIDER_DIRECTORS,
      directors: resultAllDirectors,
    });
  } catch (error) {
    console.log("ERROR DIRECTORS", error);
  }

  dispatch({ type: ACT_IS_NOT_LOADING_APP });
}

export function updateSlider() {
  return (dispatch, getStore) => {
    let sliders = getStore().sl.sliders;
    localStorage.setItem("sliders", JSON.stringify(sliders));
    dispatch({
      type: ACT_SLIDER_UPDATE,
      sliders,
    });
  };
}

export function getSlidersAction(offset = 0, limit = 6) {
  return dispatch => {
    getSliders(offset, limit).then(result => {
      dispatch({
        type: ACT_SLIDER_CHANGE,
        props: {
          sliders: result.data.map(fillMovies),
          loadingSliders: false,
        },
      });
    });
  };
}

export function getMoviesBySliderIdAction(sliderId, offset = 0, limit = 6) {
  try {
    // console.log("getMoviesBySliderIdAction", sliderId,offset, limit)
    return (dispatch, getStore) => {
      // console.log("getMoviesBySliderIdAction2", dispatch, getStore().sl)
      const staticSliders = getStore().sl.staticSliders;
      const sliders = getStore().sl.sliders;
      getMoviesBySliderId(sliderId, offset, limit)
        .then(result => {
          //  console.log("getSliders", result, staticSliders)
          let idx = staticSliders.findIndex(s => s.sl_id === sliderId);
          if (idx === -1) {
            //Slider
            idx = sliders.findIndex(s => s.sl_id === sliderId);
            let newSliders = sliders.slice(0);
            for (let i = 0; i < result.data.length; i++) {
              newSliders[idx].movies[offset + i] = result.data[i];
            }
            dispatch({
              type: ACT_SLIDER_CHANGE,
              props: {
                sliders: newSliders,
              },
            });
          } else {
            //SliderStatic
            let newStaticSliders = staticSliders.slice(0);
            for (let i = 0; i < result.data.length; i++) {
              newStaticSliders[idx].movies[offset + i] = result.data[i];
            }
            dispatch({
              type: ACT_SLIDER_CHANGE,
              props: {
                staticSliders: newStaticSliders,
              },
            });
          }
        })
        .catch(e => {
          console.log("ERROR getMoviesBySliderIdAction", e);
        });
    };
  } catch (err) {
    console.log("ERROR getMoviesBySliderIdAction2 ", err);
  }
}

export function getNextSlidersAction(onEndFetch, limit = 6) {
  return (dispatch, getStore) => {
    const sliders = getStore().sl.sliders;
    const countSlider = getStore().sl.countSlider;
    if (countSlider > sliders.length) {
      getSliders(sliders.length, limit).then(result => {
        const newSliders = sliders.concat(result.data);
        dispatch({
          type: ACT_SLIDER_CHANGE,
          props: {
            sliders: newSliders,
            loadingSliders: false,
          },
        });
      });
    } else {
      onEndFetch();
    }
  };
}

export function getNewsSliders(cb) {
  return async (dispatch, getStore) => {
    const sl = getStore().sl;
    console.log("GET NEW SLIDERS", sl.countSliders, sl.sliders.length);
    if (sl.countSliders > sl.sliders.length) {
      let localSliders = localStorage.getItem("sliders");
      if (localSliders) {
        localSliders = JSON.parse(localSliders);
        if (localSliders.length > 4) {
          dispatch({
            type: ACT_SLIDER_CHANGE,
            newSliders: localSliders.slice(
              sl.sliders.length,
              sl.sliders.length + 4,
            ),
          });

          let result = await getAllSliders(sl.sliders.length, 4);
          let newSliders = validateSliders(
            localSliders.slice(sl.sliders.length, sl.sliders.length + 4),
            result.data.map(fillMovies),
          );
          if (newSliders) {
            localStorage.setItem(
              "sliders",
              JSON.stringify(sl.sliders.concat(newSliders)),
            );
            dispatch({
              type: ACT_SLIDER_CHANGE,
              newSliders,
            });
          } else {
          }
          cb && cb();
        } else {
          let result = await getAllSliders(sl.sliders.length, 4);
          let newSliders = result.data.map(fillMovies);
          localStorage.setItem(
            "sliders",
            JSON.stringify(sl.sliders.concat(newSliders)),
          );
          dispatch({
            type: ACT_SLIDER_CHANGE,
            newSliders,
          });
          cb && cb();
        }
      } else {
        console.log("error get new sliders");
      }
    }
  };
}
