import { API, HEADERS } from "./APIConnector";

export const fetchExploreGenres = async ({ genres, countries }) => {
  let request = {
    method: "GET",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
  };

  // console.log(`/explore?genres=${genres}&countries=${countries}`);
  const res = await fetch(
    API + `/explore?genres=${genres}&countries=${countries}`,
    request
  );

  const data = await res.json();

  return data;
};
