import { API, HEADERS } from "./APIConnector";

export const fetchCountries = async () => {
  let request = {
    method: "GET",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(API + "/countries", request);

  const data = await res.json();

  return data;
};
