/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Paper, Typography, TextField, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import LoadingMsgModal from "../components/LoadingMsgModal";
import ModalInfo from "../components/ModalInfo";
import { fetchAllGenres, deleteGenre } from "../provider/GenreService";
import { deleteGenresSchema } from "../schemas/validators";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  genreSubtitle: {
    marginLeft: "10px",
    color: "#858585",
  },
});

const DeleteGenre = ({ history }: { history: any }) => {
  const classes = useStyles();
  const initialValues = {
    genresSelected: [],
  };
  const [reloadFlag, setReloadFlag] = useState(true);
  const [genres, setGenres] = useState([]);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    open: false,
    message: "",
    onConfirmation: () => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isLoading("Cargando géneros...");
    fetchAllGenres()
      .then(data => {
        isNotLoading();
        if (data.code === 200) {
          setGenres(data.data.rows);
        } else {
          setInfoModal(prevState => ({
            ...prevState,
            open: true,
            message: "Ocurrió un error al cargar los géneros",
            onConfirmation: () => handleCloseInfoModal(),
          }));
        }
      })
      .catch(err => {
        console.log(err);
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Ocurrió un error al cargar los generos",
          onConfirmation: () => handleCloseInfoModal(),
        }));
      });
  }, [reloadFlag]);

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      onConfirmation: () => {},
    }));
  };

  const onSubmitDeleteGenre = async (genres: any, resetForm: any) => {
    isLoading("Borrando...");
    const genresIds = genres.map((genre: any) => genre.ge_id);
    const deleteResponse = await deleteGenre(genresIds);
    if (deleteResponse.code === 200) {
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Géneros eliminados con éxito",
        onConfirmation: () => {
          handleCloseInfoModal();
          setReloadFlag(!reloadFlag);
        },
      }));
      resetForm();
    } else {
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Ocurrió un error al eliminar los géneros",
        onConfirmation: () => {
          handleCloseInfoModal();
        },
      }));
    }
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Eliminar género"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={undefined}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalInfo
        open={infoModal.open}
        message={infoModal.message}
        onConfirmation={infoModal.onConfirmation}
      />
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={deleteGenresSchema}
              onSubmit={(values, { resetForm }) => {
                onSubmitDeleteGenre(values.genresSelected, resetForm);
              }}>
              {({ values, handleSubmit, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        Seleccione el/los género/s que desea eliminar
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        noOptionsText="Sin opciones"
                        options={genres}
                        getOptionLabel={(genre: any) => genre.ge_name}
                        value={values.genresSelected}
                        onChange={(e, value) => {
                          setFieldValue("genresSelected", value);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Seleccione el/los géneros"
                            variant="outlined"
                            error={errors.genresSelected ? true : false}
                            helperText={
                              errors.genresSelected && errors.genresSelected
                            }
                          />
                        )}
                        renderOption={(
                          props: any,
                          option: any,
                          { selected }: any,
                        ) => {
                          const { ...optionProps } = props;
                          return (
                            <li {...optionProps} key={option.ge_id}>
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 4 }}
                                checked={selected}
                              />
                              {
                                <Fragment>
                                  <Typography>{option.ge_name}</Typography>
                                  {option.ge_type !== "N/A" && (
                                    <Typography
                                      variant="caption"
                                      className={classes.genreSubtitle}>
                                      {option.ge_type}
                                    </Typography>
                                  )}
                                </Fragment>
                              }
                            </li>
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-hover">
                        Eliminar género
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default DeleteGenre;
