import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initHomeAction } from "../actions/HomeActions";
import { listAction } from "../actions/MyListAction";
import { scrollToTop } from "../common/Util";
import {
  getSliderByUserWatching,
  getListByUser,
} from "../provider/SliderServices";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BannerCarousel from "../components/BannerCarousel";
import DirectorCarousel from "../components/DirectorCarousel";
import SliderCarousel from "../components/SliderCarousel";
import { LoaderApp } from "../components/Loader";
import { Grow } from "@mui/material";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import ScrollButton from "../components/ScrollToTop";
import useWindowSize from "../hooks/useWindowSize";
import { Helmet } from "react-helmet";
import { ACT_IS_LOADING_APP } from "../actions/ActionTypes";

const Home = props => {
  const windowSize = useWindowSize();
  let ml = useSelector(state => state.ml);
  let usr = useSelector(state => state.usr);
  let sl = useSelector(state => state.sl);
  let bn = useSelector(state => state.bn);
  let loadingApp = useSelector(state => state.loading);

  const dispatch = useDispatch();
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const [continueWatchingSlider, setContinueWatchingSlider] = useState({
    sl_id: -2,
    sl_name: "Continuar viendo",
    count_movies: 0,
    movies: [],
  });

  useEffect(function toTop() {
    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch({ type: ACT_IS_LOADING_APP });
    dispatch(initHomeAction);
  }, [dispatch]);

  useEffect(() => {
    if (usr.user) {
      getSliderByUserWatching().then(result => {
        if (result.count_movies !== continueWatchingSlider.count_movies) {
          setContinueWatchingSlider(result);
        }
      });
    }
  }, [continueWatchingSlider, usr.user]);

  useEffect(() => {
    if (usr.user) {
      getListByUser().then(result => {
        if (result.count_movies !== ml.count_movies) {
          dispatch(listAction(result));
        }
      });
    }
  }, [dispatch, ml.count_movies, ml.movies, usr.user]);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = action => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrio un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrio un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  const renderSlider = (slider, index, freeMode = false) => {
    const weekUiSlider = "container-fluid wrapper-movie-news-week px-0 py-5";
    const newsUiSlider = "container-fluid wrapper-movie-news px-0 py-5";
    const general =
      "container-fluid wrapper-movie-news vortex-slider-general px-0 py-5";

    let uiSlider = general;
    switch (index) {
      case 0:
        uiSlider = weekUiSlider;
        break;
      case 1:
        uiSlider = newsUiSlider;
        break;
      default:
        uiSlider = general;
    }
    //   // if((previousIndex>=0&&activeIndex>=6)){
    //   const countMovies = slider.count_movies;
    //   let pageSize;
    //   if (windowSize.width >= 768 && windowSize.width < 1200) pageSize = 6;
    //   if (windowSize.width >= 1200 && windowSize.width < 1800) pageSize = 7;
    //   if (windowSize.width >= 1800) pageSize = 8;
    //   if (getDeviceType() === SMALL_DEVICE) {
    //     pageSize = 3;
    //   }
    //   let offset = activeIndex - pageSize;
    //   if (previousIndex + pageSize === activeIndex) {
    //     //Muevo a la derecha
    //     offset = activeIndex + pageSize;
    //   } else {
    //     if (previousIndex === 0 || offset < 0) {
    //       offset = countMovies - pageSize * 2;
    //     }
    //   }
    //   // console.log("ON SLIDE CHANGE", slider.sl_id, previousIndex, activeIndex, offset)
    //   // console.log(slider.movies[offset]);
    //   for (; offset < countMovies; offset++) {
    //     if (!(slider.movies[offset] && slider.movies[offset].mo_id)) {
    //       break;
    //     }
    //   }
    //   if (offset < countMovies) {
    //     //Si el offset está dentro del rango
    //     // console.log("FETCH", slider.sl_id)
    //     // dispatch(getMoviesBySliderIdAction(slider.sl_id, offset, FETCH_SIZE))
    //     let moviesNoIds = [];
    //     slider.movies.forEach(el => {
    //       if (Object.keys(el).length > 0) {
    //         moviesNoIds.push(el.mo_id);
    //       }
    //     });
    //     getMoviesBySliderId(slider.sl_id, offset, FETCH_SIZE, moviesNoIds).then(
    //       result => {
    //         let movies = result.movies;
    //         // let newSlider = {...slider}
    //         for (let i = 0; i < movies.length; i++) {
    //           slider.movies[offset + i] = movies[i];
    //         }
    //         dispatch(updateSlider());
    //       },
    //     );
    //   }
    //   // }
    // };

    return (
      <section key={"k" + index}>
        <div
          className={uiSlider}
          style={{
            marginTop:
              !usr.user && index === 0
                ? windowSize.width < 1024
                  ? "30px"
                  : "50px"
                : "0px",
          }}>
          <div className="row">
            <div className="col-12">
              <div className="iq-main-header d-flex align-items-center justify-content-between mb-0">
                <h4
                  className="main-title m-0"
                  style={
                    slider.sl_order > 5
                      ? {
                          backgroundImage:
                            "url(./images/icons/xiclos-icon-red.png)",
                        }
                      : undefined
                  }>
                  <a href="#">{slider.sl_name.toUpperCase()}</a>
                </h4>
              </div>
              <div className="xiclos-tiras-ul-container">
                {/* <div id="vortex-week-ul-loader" className="divLoading">
                        <div className="loading-content">
                           <i className="ri-loader-4-line"></i>
                        </div>
                     </div> */}
                <SliderCarousel
                  slider={slider}
                  onSlideChange={() => {}}
                  handleModal={handleModal}
                  freeMode={freeMode}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Xiclos</title>
        <meta name="description" content="Xiclos - Saber mirar" />
      </Helmet>
      {/* <div id="loading">
          <img src="./images/logo.png" alt="Xiclos"/>
          <i className="ri-loader-4-line"></i>
      </div> */}
      <div id="front-page-container">
        <Header />
        <section id="home" className="iq-main-slider p-0 pt-0">
          <div id="home-slider">
            {bn.banners && bn.banners.length > 0 && (
              <BannerCarousel banners={bn.banners} />
            )}
            {/* <div>
                <div id="pepe" className="slider m-0 p-0 carousel">
                </div>
            </div> */}
          </div>
        </section>
      </div>
      <div>
        {continueWatchingSlider?.movies &&
        continueWatchingSlider?.movies.length > 0 ? (
          <Grow in={true}>
            <section key={"k-continue-watching"}>
              <div
                style={{ marginTop: windowSize.width < 1024 ? "30px" : "50px" }}
                className={
                  "container-fluid wrapper-movie-news vortex-slider-general px-0 py-5"
                }>
                <div className="row">
                  <div className="col-12">
                    <div className="iq-main-header d-flex align-items-center justify-content-between mb-0">
                      <h4
                        className="main-title m-0"
                        style={{
                          backgroundImage:
                            "url(./images/icons/xiclos-icon-red.png)",
                        }}>
                        <a href="#">
                          {continueWatchingSlider.sl_name.toUpperCase()}
                        </a>
                      </h4>
                    </div>
                    <div className="xiclos-tiras-ul-container">
                      <SliderCarousel
                        slider={continueWatchingSlider}
                        onSlideChange={() => {}}
                        handleModal={handleModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Grow>
        ) : (
          ""
        )}
        {ml?.movies && ml?.movies.length > 0 ? (
          <Grow in={true}>
            <section key={"k-my-list"}>
              <div
                className={
                  "container-fluid wrapper-movie-news vortex-slider-general px-0 py-5"
                }>
                <div className="row">
                  <div className="col-12">
                    <div className="iq-main-header d-flex align-items-center justify-content-between mb-0">
                      <h4
                        className="main-title m-0"
                        style={{
                          backgroundImage:
                            "url(./images/icons/xiclos-icon-red.png)",
                        }}>
                        <a href="#">{ml.sl_name.toUpperCase()}</a>
                      </h4>
                    </div>
                    <div className="xiclos-tiras-ul-container">
                      <SliderCarousel
                        slider={ml}
                        onSlideChange={() => {}}
                        handleModal={handleModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Grow>
        ) : (
          ""
        )}
        {sl.sliders.length > 0 &&
          sl.sliders.map((el, i) => {
            if (i < 4) {
              if (el.sl_id === 9999999999) {
                // MODO FESTIVAL HARDCODEADO CAMBIAR POR SLIDER CORRESPONDIENTE EN PROD
                return renderSlider(el, i, true);
              } else {
                return renderSlider(el, i);
              }
            }
            return null;
          })}
      </div>
      {sl.directors.length > 0 && (
        <section id="iq-directors mt-3">
          <div className="row">
            <div className="col-sm-12 overflow-hidden">
              <div className="iq-main-header d-flex align-items-center justify-content-between wrapper-directors">
                <h4 className="main-title">
                  <Link to="/cine-club">CINECLUB</Link>
                </h4>
              </div>
              <DirectorCarousel directors={sl.directors} />
            </div>
          </div>
        </section>
      )}

      <div style={{ paddingTop: 40 }}>
        {sl.sliders.length > 4 &&
          sl.sliders.map((s, i) => {
            if (i >= 4) {
              return renderSlider(s, i);
            }
            return null;
          })}
      </div>
      {!loadingApp.isLoading && sl.sliders.length === 4 && (
        <div id="loader-slider" className="divLoading">
          <div className="loading-content">
            <i className="ri-loader-4-line"></i>
          </div>
        </div>
      )}
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
      <LoaderApp open={loadingApp.isLoading} />
    </div>
  );
};

export default Home;
