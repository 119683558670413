/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Paper, Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { getBanners } from "../provider/BannerServices";
import LoadingMsgModal from "../components/LoadingMsgModal";
import ModalInfo from "../components/ModalInfo";
import { deleteBanner, deleteBannerImage } from "../provider/BannerServices";
import { deleteBannerSchema } from "../schemas/validators";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

const DeleteBanner = ({ history }: { history: any }) => {
  const classes = useStyles();
  const initialValues = {
    bannerSelected: null,
  };
  const [reloadFlag, setReloadFlag] = useState(true);
  const [banners, setBanners] = useState([]);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    open: false,
    message: "",
    onConfirmation: () => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isLoading("Cargando banners...");
    getBanners()
      .then(res => {
        isNotLoading();
        setBanners(res);
      })
      .catch(err => {
        console.log(err);
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Ocurrió un error al cargar los banners",
          onConfirmation: () => handleCloseInfoModal(),
        }));
      });
  }, [reloadFlag]);

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      onConfirmation: () => {},
    }));
  };

  const onSubmitDeleteBanner = async (banner: any, resetForm: any) => {
    isLoading("Borrando...");
    const deleteResponse = await deleteBanner(banner.bn_id);
    if (deleteResponse.code === 200) {
      if (banner.bn_image && banner.bn_movie !== 1) {
        const linkImageCut = banner.bn_image.split("?");
        const finalLinkImage = linkImageCut[0].split(`%2F`)[1];
        await deleteBannerImage(finalLinkImage);
      }
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Banner eliminado con éxito",
        onConfirmation: () => {
          handleCloseInfoModal();
          setReloadFlag(!reloadFlag);
        },
      }));
      resetForm();
    } else {
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Ocurrió un error al eliminar el banner",
        onConfirmation: () => handleCloseInfoModal(),
      }));
    }
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Eliminar banner"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={undefined}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalInfo
        open={infoModal.open}
        message={infoModal.message}
        onConfirmation={infoModal.onConfirmation}
      />
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={deleteBannerSchema}
              onSubmit={(values, { resetForm, setFieldError }) => {
                if (values.bannerSelected === null) {
                  setFieldError("bannerSelected", "Debe seleccionar un banner");
                } else {
                  onSubmitDeleteBanner(values.bannerSelected, resetForm);
                }
              }}>
              {({ values, handleSubmit, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        Seleccione el banner que desea eliminar
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={errors.select}
                        variant="outlined">
                        <Select
                          multiple
                          value={values.select}
                          name="select"
                          onChange={handleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getcontentanchorel: null,
                          }}
                          renderValue={selected => (
                            <div className={classes.chips}>
                              {selected.map(value => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}>
                          {banners.map(banner => (
                            <MenuItem key={banner.bn_id} value={banner.bn_name}>
                              {banner.bn_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.select && (
                          <FormHelperText>Error</FormHelperText>
                        )}
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        noOptionsText="Sin opciones"
                        options={banners}
                        getOptionLabel={(banner: any) => banner.bn_name}
                        value={values.bannerSelected}
                        onChange={(e, value) => {
                          setFieldValue(
                            "bannerSelected",
                            value !== null
                              ? value
                              : initialValues.bannerSelected,
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Seleccione el banner"
                            variant="outlined"
                            error={errors.bannerSelected ? true : false}
                            helperText={
                              errors.bannerSelected && errors.bannerSelected
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-hover">
                        Eliminar banner
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default DeleteBanner;
