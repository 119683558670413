import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [openTerms, setOpenTerms] = useState(false);

  const handleClose = () => {
    setOpenTerms(false);
  };

  return (
    <>
      <section id="iq-devices">
        <div className="container-fluid wrapper-devices">
          <div className="row">
            <div className="col-sm-12 py-4 text-center">
              <img src="../images/devices-xiclos-a.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <footer className="mb-0">
        <div className="container-fluid">
          <div className="block-space">
            <div className="row  py-lg-5 py-sm-5 py-xs-4">
              <div className="col-lg-2 col-md-2 mb-md-0 text-footer mb-4">
                <ul className="f-link list-unstyled mb-0">
                  <li id="first-list-li-footer">Redes</li>
                  <li>
                    <a
                      href="https://www.facebook.com/xiclos/"
                      target="_blank"
                      rel="noreferrer">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/hashtag/Xiclos?src=hashtag_click"
                      target="_blank"
                      rel="noreferrer">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/xiclosar/"
                      target="_blank"
                      rel="noreferrer">
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-2 mb-md-0 mb-4">
                <ul className="f-link list-unstyled mb-0 text-footer">
                  <li id="second-list-li-footer">Sobre Xiclos</li>
                  <li>
                    <a
                      href="/"
                      onClick={e => {
                        e.preventDefault();
                        setOpenTerms(true);
                      }}>
                      Términos y Condiciones
                    </a>
                  </li>
                  <li>
                    <Link to="/who">Quiénes somos</Link>
                  </li>
                  <li>
                    <Link to="/faqs">Preguntas Frecuentes</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contacto</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-5 ml-lg-auto col-md-6">
                <div className="row">
                  <div className="col-12">
                    <p className="text-footer" style={{ fontSize: 25 }}>
                      Suscribite a nuestro newsletter y recibí las últimas
                      novedades
                    </p>
                  </div>
                  <div className="col-12 d-flex ">
                    <input
                      className="input-newsletter text-footer"
                      type="text"
                      placeholder="Email"
                    />
                    <button className="btn btn-newsletter" type="button">
                      Suscribirme
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright row py-2">
            <div className="col-lg-12 col-md-4 r-mt-15">
              <p style={{ marginTop: 25 }} className="text-footer">
                Copyright © 2021 - XICLOS - Todos los derechos reservados.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <Dialog
        disableEnforceFocus
        disableScrollLock
        open={openTerms}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span style={{ color: "var(--xiclos-red)" }}>
            {" "}
            Términos y Condiciones @ Xiclos
          </span>
        </DialogTitle>
        <DialogContent>
          <p>
            <b>XICLOS.COM</b> (en adelante <b>“XICLOS”</b>) es un sitio web de
            propiedad de <b>FABIAN GIL NAVARRO</b>, C.U.I.T. ******** con
            domicilio legal en la calle Angel J. Carranza 1458 –Dpto. “1”- de la
            Ciudad de Buenos Aires. <b>XICLOS</b> es un servicio de
            entretenimiento por internet mediante el cual sus miembros, mediante
            el pago de una membresía mensual tienen acceso a programas de TV,
            películas, documentales y series (en adelante{" "}
            <b>“LOS CONTENIDOS”</b>) sujetas a disponibilidad de <b>XICLOS</b>,
            a los cuales pueden acceder en cualquier momento y en casi cualquier
            pantalla conectada a internet, pudiendo reproducir, pausar y ver un
            título, sin publicidad ni compromisos, todo ello, con sujeción a los
            siguientes términos y condiciones de uso:
          </p>

          <p>
            1. Generalidades.
            <br />
            1.1. XICLOS brinda un servicio de suscripción que permite a sus
            miembros acceder a los contenidos de XICLOS transmitidos a través de
            Internet a ciertas TV y otros dispositivos conectados a Internet
            (&quot;dispositivos listos para XICLOS&quot;).
            <br />
            <br />
            1.2. El servicio de XICLOS es prestado en los países de Argentina,
            Uruguay, Chile y Paraguay, sin perjuicio de su eventual y futura
            extensión a otros países.
            <br />
            <br />
            1.3. Los presentes Términos y Condiciones de Uso rigen la
            utilización que haga de nuestro servicio. Según se utilice en estos
            Términos de uso, las frases “el servicio de Xiclos”, “nuestro
            servicio” o “el servicio” se refieren al servicio brindado por
            XICLOS para descubrir y ver los contenidos, incluidas todas las
            características y funcionalidades, recomendaciones y críticas, el
            sitio web y las interfaces de usuario, además de todo el contenido y
            software asociado a nuestro servicio.
          </p>

          <p>
            2. Membresía <br />
            2.1. Para ser miembro de XICLOS debe abonar una membresía que
            continuará mes a mes hasta que la cancele. Para usar el servicio de
            XICLOS, debe tener acceso a Internet y un dispositivo listo para
            XICLOS, y proporcionar una medio de pago actual, válido y aceptado,
            que podrá ser actualizado cuando resulte necesario (&quot;Forma de
            pago&quot;). Cuando la cancelación de la membresía se produzca con
            posterioridad a la fecha de facturación mensual, quedamos igualmente
            autorizados a cobrarle a su medio de pago, íntegramente, la
            membresía del mes en que se produjera la cancelación. Podrá
            encontrar los detalles específicos sobre su membresía en nuestro
            sitio web, accediendo a su cuenta bajo su nombre de perfil.
            <br />
            <br />
            2.2. Precio Mensual de la Membresía: Para visualizar el precio
            mensual de la membresía y la fecha de finalización del último
            período abonado, visite nuestro sitio web y acceda al vínculo
            &quot;Detalles de facturación&quot; accediendo a su cuenta bajo su
            nombre de perfil.
            <br />
            <br />
            2.3. <b>Facturación</b>: Los cargos de membresía por el servicio de
            XICLOS y cualquier otro cargo en el que incurra en relación con el
            uso que haga del servicio de XICLOS, como impuestos y posibles
            gastos de transacción, se cobrarán mensualmente a su Medio de Pago
            en el día calendario correspondiente al comienzo de la porción de
            pago de su membresía. En ciertos casos, su fecha de pago podría
            cambiar, por ejemplo si su Medio de pago no se estableció
            satisfactoriamente o si su membresía paga comenzó un día que no está
            incluido en un determinado mes. Puede ver su próxima fecha de pago
            ingresando en nuestro sitio web al vínculo &quot;Detalles de
            facturación&quot; accediendo a su cuenta bajo su nombre de perfil.
            <br />
            <br />
            2.4. Cambio del Medio de Pago: Puede cambiar su Medio de Pago al
            visitar nuestro sitio web ingresando en el vínculo &quot;Tu
            cuenta&quot;. Si el pago no se pudiera hacer satisfactoriamente,
            debido a la fecha de vencimiento, la falta de fondos o si usted no
            modifica la información de su Medio de pago o cancelara su cuenta,
            podemos suspender su acceso al servicio hasta que obtengamos un
            Medio de pago válido. Al actualizar su Medio de pago, usted nos
            autoriza a continuar cobrando a través del Medio de pago actualizado
            y usted sigue siendo responsable de los importes no cobrados. Esto
            podría resultar en un cambio en las fechas de cobro. Para algunas
            Medios de pago, el emisor puede cobrarle ciertos cargos, como cargos
            de transacción extranjera u otros cargos relacionados con el
            procesamiento de su Medio de pago. Los impuestos locales varían en
            función del Medio de pago utilizado.
            <br />
            <br />
            2.5. Cancelación de la Membresía: Vd. puede cancelar la membresía de
            XICLOS en cualquier momento, y continuará teniendo acceso al
            servicio hasta el final del período de facturación mensual. En la
            medida permitida por la ley aplicable, los pagos no son
            reembolsables y no se otorgarán reembolsos ni créditos por los
            períodos de membresía utilizados parcialmente o por el contenido de
            XICLOS no visto. Para cancelar, visite la página &quot;Tu
            cuenta&quot; y siga las instrucciones. Si cancela su membresía, su
            cuenta se cerrará automáticamente al final de su facturación mensual
            actual. Para ver cuándo se cerrará su cuenta, haga clic en
            &quot;Detalles facturación&quot; en la página &quot;Tu cuenta&quot;.
            Si se suscribió a XICLOS mediante su cuenta con un tercero como
            Medio de pago y desea cancelar su membresía de XICLOS en cualquier
            momento, es posible que tenga que hacerlo a través de dicho tercero,
            ya sea, al visitar su cuenta con el tercero correspondiente para
            desactivar su renovación automática o al cancelar la suscripción al
            servicio de XICLOS a través de ese tercero.
            <br />
            <br />
            2.6. Cambios en el precio y Planes de servicio: Podemos cambiar
            nuestros planes de servicio y el precio de nuestro servicio de vez
            en cuando. Sin embargo, cualquier cambio en los precios o en
            nuestros planes de servicio se aplicará a usted no antes de los 30
            días siguientes a la notificación.
          </p>

          <p>
            3. Servicio <br />
            3.1. Para acceder al servicio de XICLOS debe tener 18 años, o la
            mayoría de edad según la ley vigente en el territorio donde Vd.
            acceda al servicio. Los menores pueden usar el servicio con la
            supervisión de un adulto.
            <br />
            <br />
            3.2. El servicio de XICLOS y los contenidos a los que acceda a
            través de nuestro servicio son para uso personal quedando
            absolutamente prohibida la comercialización de los mismos. La
            membresía que le otorgamos es una licencia limitada, no exclusiva e
            intransferible para acceder al servicio de XICLOS con el propósito
            de ver el contenido de XICLOS a través de nuestro servicio de
            streaming. Excepto dicha licencia limitada, no se le transferirá
            ningún derecho o título. Usted acepta que no usará el servicio para
            presentaciones públicas.
            <br />
            <br />
            3.3. Usted puede ver el contenido de XICLOS principalmente en el
            país donde estableció su cuenta y solo en los lugares geográficos en
            los que ofrecemos nuestro servicio y donde se tenga licencia para
            ese contenido. El contenido que puede estar disponible para ver
            puede variar según la ubicación geográfica y cambia periódicamente.
            La cantidad de dispositivos en los que puede ver simultáneamente
            depende del plan de suscripción elegido y se especifica en la página
            &quot;Tu cuenta&quot;.
            <br />
            <br />
            3.4. XICLOS actualiza el servicio y los contenidos continuamente.
            También podemos probar, actualizar o modificar regularmente
            distintos aspectos relativos a nuestro servicio, incluidos nuestro
            sitio web, las interfaces de usuario, las funciones promocionales y
            la disponibilidad de contenido de XICLOS.
            <br />
            <br />
            3.5. Eventualmente, parte del contenido de XICLOS podrá estar
            disponible para su descarga temporal y la visualización offline en
            ciertos dispositivos que resulten compatibles a tales efectos. En
            tal eventualidad, se aplicarán restricciones, que podrán alcanzar a
            la cantidad de Títulos a descargar de manera offline por cada
            cuenta, al período en el cual deberá comenzar a ver los Títulos
            descargados, al tiempo en que permanecerán accesibles los mismos,
            etc.
            <br />
            <br />
            3.6. El miembro de XICLOS acepta usar el servicio con todas las
            características y funcionalidades propias del mismo, bajo
            conformidad con todas las leyes, normas y reglamentaciones vigentes,
            o cualquier otra restricción al uso del servicio o sus contenidos,
            comprometiéndose a no archivar, reproducir, distribuir, modificar,
            mostrar, ejecutar, publicar, otorgar licencias, crear obras
            derivadas basadas en el servicio, u ofrecer en venta, o usar (a
            excepción de que se autorice explícitamente en estos Términos de
            uso) contenido e información contenida en u obtenida del servicio de
            XICLOS. También se compromete a no eliminar, alterar, desactivar,
            interferir con o burlar las protecciones de contenido del servicio
            de XICLOS; no usar ninguna forma automatizada, creada o a crearse,
            para acceder al servicio de XICLOS; ni descompilar, realizar
            ingeniería inversa, desarmar el software u otro producto o proceso a
            los que se acceda a través del servicio de XICLOS; ni a introducir
            de alguna manera un código o producto o manipular el contenido del
            servicio de XICLOS; ni usar método alguno de análisis, extracción u
            obtención de datos. Asimismo, se compromete a no subir, publicar,
            enviar por email ni transmitir de cualquier otra forma ningún
            material diseñado para interrumpir, destruir o limitar la
            funcionalidad del software de computación, hardware o equipos de
            telecomunicaciones asociados con el servicio de XICLOS, incluido
            material que contenga virus de software o cualquier otro código,
            archivos o programas. Si Vd. incurriera en alguna de las acciones
            precedentemente detalladas o en cualquier otra violación de los
            Términos de uso o usara el servicio de forma ilegal o fraudulenta,
            XICLOS queda autorizada a terminar o restringir su uso de nuestro
            servicio.
            <br />
            <br />
            3.7. La calidad de la imagen del contenido de XICLOS puede variar de
            un dispositivo a otro e incluso puede verse afectada por diversos
            factores, tales como la ubicación, el ancho de banda disponible o la
            velocidad de la conexión a Internet. La disponibilidad del contenido
            en alta definición (HD), ultraalta definición (Ultra HD) y alto
            rango dinámico (HDR) depende de su servicio de Internet y del
            dispositivo en uso. No todo el contenido está disponible en todos
            los formatos ni todos los planes de suscripción le permiten recibir
            contenido en todos los formatos. La configuración de reproducción
            predeterminada en las redes celulares excluye contenido HD, Ultra HD
            y HDR. La velocidad mínima de conexión para la calidad SD es de 0,5
            Mbps., sin perjuicio de recomendar una conexión más rápida para
            mejorar la calidad de video. Todos los cargos de acceso a Internet
            correrán por su cuenta. Solicite a su proveedor de Internet
            información acerca de los posibles cargos de consumo de datos por
            uso de Internet. El tiempo que lleva comenzar a ver contenido de
            XICLOS variará según diversos factores, incluido el lugar donde se
            encuentra, el ancho de banda disponible en ese momento, el contenido
            que haya seleccionado y la configuración de su dispositivo.
            <br />
            <br />
            3.8. El software de XICLOS es desarrollado por o a pedido de XICLOS
            y fue diseñado para permitir ver contenido a través de dispositivos
            listos para XICLOS. El software de XICLOS puede variar según el
            dispositivo y el medio, y la funcionalidad y las funciones también
            pueden variar de un dispositivo a otro. Usted reconoce que el uso
            del servicio puede requerir software de terceros que esté sujeto a
            licencias de terceros. Usted acepta que puede recibir
            automáticamente versiones actualizadas del software de XICLOS o de
            terceros relacionado.
            <br />
            <br />
            3.9. <u>Servicio al cliente</u>. Si necesita obtener más información
            sobre nuestro servicio y sus funciones, o si necesita asistencia con
            su cuenta, solicite ayuda en nuestro sitio web. En algunos casos, el
            Servicio al cliente podrá ayudarlo mejor utilizando una herramienta
            de asistencia de acceso remoto con el que se accede completamente a
            su computadora. Si no desea que tengamos este acceso, no debería
            autorizar la asistencia a través de la herramienta de acceso remoto
            y nosotros le ayudaremos de otra forma. En el caso en que haya un
            conflicto entre estos Términos de uso y la información recogida por
            el Servicio al cliente u otras secciones de nuestro sitio web, estos
            Términos de uso serán los que regirán.
          </p>

          <p>
            4. Contraseñas y Acceso a la Cuenta. <br />
            4.1. El miembro que creó la cuenta de XICLOS y al que se le facturan
            los cargos a través de su Medio de Pago (el &quot;Titular de la
            cuenta&quot;) tiene acceso y control sobre la cuenta de XICLOS y
            sobre los dispositivos que se usan para acceder a nuestro servicio.
            Para mantener el control sobre la cuenta y evitar que alguien acceda
            a la cuenta, el Titular de la cuenta debería mantener el control
            sobre los dispositivos que se utilizan para acceder al servicio y no
            revelar a nadie la contraseña ni los detalles del Medio de pago
            asociado a la misma. Es su responsabilidad actualizar y mantener la
            exactitud de la información personal que nos brinda respecto de su
            cuenta. En cualquier momento, XICLOS podrá decidir cancelar su
            cuenta o suspenderla para protegerlo a usted, a XICLOS o a otros
            miembros contra el robo de identidad u otra actividad fraudulenta.
            <br />
            <br />
            4.2. Acceso a &quot;Tu cuenta&quot; y &quot;Perfiles&quot;. A
            efectos de facilitarle el acceso a su cuenta y para administrar
            mejor el servicio de XICLOS, usamos tecnología que nos permite
            reconocerlo como el titular de la cuenta y darle acceso directo a
            esta sin solicitarle que vuelva a introducir ninguna contraseña u
            otra identificación de usuario al volver a ingresar al servicio de
            XICLOS. Usted puede optar por no aprovechar esta función en su
            computadora desmarcando el cuadro &quot;Recordarme en este
            dispositivo&quot; cuando inicia sesión en el sitio web.
            <br />
            <br />
            4.3. Para eliminar el acceso a su cuenta de XICLOS desde sus
            dispositivos: (a) acceda a su cuenta, elija Cerrar sesión en todos
            los dispositivos, y siga las instrucciones para desactivar sus
            dispositivos (tenga en cuenta que la desactivación puede no ser
            inmediata) o (b) elimine la configuración de XICLOS de su
            dispositivo (los pasos necesarios varían según el dispositivo, y la
            opción no está disponible en todos los dispositivos). Siempre que
            sea posible, los usuarios de dispositivos públicos o compartidos
            deben cerrar sesión al finalizar cada visita. Si vende o devuelve
            una computadora o un dispositivo listo para XICLOS, debería cerrar
            sesión y desactivar el dispositivo antes de hacerlo. Si usted no
            mantiene de forma segura su contraseña o su dispositivo, o no cierra
            sesión o desactiva su dispositivo, los usuarios posteriores podrán
            acceder a la información de su cuenta, incluida su información
            personal.
            <br />
            <br />
            4.4. Si comparte o permite que otros tengan acceso a su cuenta,
            podrán ver su información (incluida, en algunos casos, la
            información personal) como su historial de visualización,
            calificaciones, reseñas e información de la cuenta (incluida su
            dirección de email u otra información en &quot;Tu cuenta&quot;).
            Esto es así incluso si usa nuestra función de perfiles.
            <br />
            <br />
            4.5. Los perfiles permiten que los distintos miembros de su hogar
            tengan su propia experiencia personalizada de XICLOS, de acuerdo con
            las series y las películas que cada uno disfruta, como
            recomendaciones personalizadas e historiales de visualización
            separados. Tenga en consideración que los perfiles estarán
            disponibles para cualquiera que use su cuenta de XICLOS, así que
            toda persona que tenga acceso a su cuenta puede navegar, usar,
            editar o borrar cualquier perfil. Esto debe ser explicado a las
            otras personas que tengan acceso a su cuenta, si no desea que usen o
            actualicen su perfil, asegúrese de que estén al tanto de dicha
            situación.
          </p>

          <p>
            5. <b>Exclusión de garantías y limitaciones a la responsabilidad</b>
            . El servicio de XICLOS se ofrece &quot;en el estado en que se
            encuentra&quot;, sin garantía ni condición. En particular, nuestro
            servicio no se declara sin interrupciones ni sin errores. Usted
            renuncia a todos los daños especiales o generales, directos o
            indirectos, patrimoniales o extrapatrimoniales contra nosotros.
            Estos términos no limitarán las garantías no renunciables y los
            derechos de protección al consumidor a los que usted tenga derecho
            bajo las leyes de su país de residencia.
          </p>

          <p>
            6. RENUNCIA A LA ACCION COLECTIVA. EN LA MEDIDA EN QUE LAS LEYES
            APLICABLES LO PERMITAN, USTED Y XICLOS ACUERDAN QUE CADA UNO PUEDE
            PRESENTAR RECLAMOS CONTRA LA OTRA PARTE SOLO EN NOMBRE PROPIO, Y NO
            COMO ACTORA O PARTE DE UN GRUPO EN UNA ACCIÓN COLECTIVA O
            REPRESENTATIVA.
          </p>

          <p>
            7. Declaración de privacidad <br />
            7.1 En esta Declaración de privacidad se explican nuestras
            prácticas, incluidas sus opciones, respecto de la recolección, uso,
            transferencia y transmisión de cierta información, incluida su
            información personal, por parte XICLOS.
            <br />
            <br />
            7.2 Si tiene preguntas generales sobre su cuenta, póngase en
            contacto con nuestro Servicio de Atención al Cliente para obtener
            asistencia. Su responsable de los datos y proveedor de servicios de
            XICLOS depende del país de la membresía que aparece en el email de
            confirmación de membresía o de pago.
            <br />
            <br />
            7.3 El responsable de los datos de su información personal y quien
            le proporciona el servicio es Fabián Gil Navarro. Tenga en cuenta
            que si contacta con nosotros para obtener ayuda, tanto por su
            seguridad como por la nuestra, podemos pedirle que autentique su
            identidad antes de hacer la solicitud.
            <br />
            <br />
            7.4 Recolección de la información. Recibimos y almacenamos
            información sobre usted, incluida:
            <br />
            7.4.1 La información que usted nos proporciona, que incluye su
            nombre, email, dirección, código postal, medio de pago y número de
            teléfono. Esta información se recopila de diferentes maneras, entre
            ellas, cuando usted la introduce en nuestro servicio, al interactuar
            con nuestro Servicio al Cliente, o al participar en encuestas o
            promociones de marketing
            <br />
            7.4.2 La información recopilada cuando usted elije proporcionar
            reseñas, calificaciones o preferencias, configura las preferencias
            en su cuenta, o nos brinda información a través de nuestro servicio
            o de otros medios.
            <br />
            7.4.3 La información que recopilamos automáticamente con relación a
            usted, al uso de nuestro servicio, a sus interacciones con nosotros
            y nuestra publicidad, además de aquella información relacionada con
            su computadora o cualquier dispositivo que utilice para acceder a
            nuestro servicio.
            <br />
            <br />
            7.5 La información recopilada incluye:
            <br />
            7.5.1 Su actividad en el servicio de XICLOS como las selecciones de
            los títulos, el historial de visualización y las consultas de
            búsquedas
            <br />
            7.5.2 Detalles sobre sus interacciones con el Servicio al Cliente,
            como la fecha, hora, razón de la consulta, transcripciones de
            cualquier conversación en el chat y, si nos llama, su número de
            teléfono y grabaciones de las llamadas
            <br />
            7.5.3 ID de dispositivos u otros identificadores únicos,
            características de software y dispositivo (como tipo y
            configuración), información de conexión, estadísticas sobre
            visualización de las páginas, URL de referencia, direcciones de IP
            (que nos puede informar su ubicación general), navegador e
            información de registro web estándar
            <br />
            7.5.4 La información recopilada a través del uso de cookies,
            señalizaciones web y otras tecnologías, incluidos los datos de
            publicidad (por ejemplo, información sobre la disponibilidad y
            entrega de anuncios, el URL del sitio, así como la fecha y la hora).
            Consulte nuestra sección de cookies y publicidad de Internet para
            obtener más información.
            <br />
            7.5.5 La información de otras fuentes: Podemos complementar la
            información descrita anteriormente con información obtenida de otras
            fuentes, incluida la de proveedores de datos obtenidos tanto online
            como sin conexión a Internet. Esta información complementaria puede
            incluir datos demográficos, datos basados en los intereses y
            conductas de navegación por Internet
            <br />
            <br />
            7.6 Uso de la información: Usamos la información recopilada para
            proporcionar, analizar, administrar, mejorar y personalizar nuestros
            servicios e iniciativas de marketing, para procesar su registro,
            solicitudes y pagos; y para comunicarnos con usted sobre este y
            otros temas. Por ejemplo, utilizamos la información para:
            <br />
            7.6.1. Determinar la ubicación geográfica general donde se
            encuentra, ofrecerle contenidos traducidos, brindarle
            recomendaciones de películas y programas de TV que consideramos que
            le pueden agradar, determinar su proveedor de servicio de Internet,
            y para ayudarnos a responder con mayor rapidez y eficiencia a las
            consultas y solicitudes
            <br />
            7.6.2. Prevenir, detectar e investigar actividades potencialmente
            prohibidas o ilegales, como el fraude, y hacer cumplir nuestros
            términos
            <br />
            7.6.3. Analizar y entender a nuestra audiencia, mejorar nuestro
            servicio y optimizar la selección de contenido, los algoritmos de
            recomendación y la entrega
            <br />
            7.6.4. Comunicarse con usted en relación con nuestro servicio (por
            ejemplo, por email, notificaciones, mensajes de texto y canales de
            mensajería online), para poder enviarle noticias sobre XICLOS,
            detalles sobre nuevas funciones y contenidos disponibles, así como
            ofertas especiales, anuncios promocionales y encuestas para el
            consumidor, y para ayudarlo con solicitudes operativas, como el
            restablecimiento de su contraseña
            <br />
            <br />
            7.7. Transferencia de la información. Transferimos su información
            para determinados propósitos y a terceros, de la siguiente manera:
            <br />
            7.7.1. Proveedores de servicios: Usamos otras empresas, agentes o
            contratistas (“Proveedores de servicios”) para que presten servicios
            en nuestro nombre o para que nos ayuden en la prestación del
            servicio. Por ejemplo, contratamos proveedores de servicios para
            ofrecer servicios de marketing, comunicaciones, etc, que
            personalizan y mejoran nuestro servicio, procesan las transacciones
            de las tarjetas de crédito y de otras formas de pago, brindan
            atención al cliente, cobran deudas, analizan y mejoran los datos,
            incluida la información relativa a la interacción de nuestros
            usuarios con nuestro sitio web, y procesan nuestras encuestas de
            consumo. En el transcurso de la prestación de dichos servicios,
            estas empresas pueden tener acceso a su información personal o de
            otro tipo. No las autorizamos para que usen o transfieran su
            información personal, al menos que esté relacionada con los
            servicios que proporcionan.
            <br />
            7.7.2. Promociones: Podemos ofrecer promociones o programas
            conjuntos que requieran el intercambio de información con terceros
            como condición para participar. Al realizar este tipo de
            promociones, podremos compartir su nombre y otra información
            relacionada con la realización del incentivo. Tenga en cuenta que
            los terceros son responsables de sus propias prácticas de
            privacidad.
            <br />
            <br />
            7.8. XICLOS y sus Proveedores de servicios pueden transferir
            información personal cuando nosotros consideremos o ellos consideren
            razonable que dicha transferencia sea necesaria para (a) cumplir con
            cualquier legislación, reglamentación, proceso legal o solicitud
            gubernamental correspondiente; (b) hacer cumplir términos de uso
            correspondientes, incluida la investigación de posibles
            infracciones; (c) detectar, prevenir o abordar actividades ilegales
            o presuntamente ilegales (incluidas las de pago fraudulento),
            problemas de seguridad o técnicos; o (d) proteger de daño contra los
            derechos, la propiedad o la seguridad de XICLOS, sus usuarios o el
            público, según lo requiera o permita la ley.
            <br />
            <br />
            7.9. Transferencias de negocios: En relación con una reorganización,
            reestructuración, fusión o venta, o cualquier otra transferencia de
            bienes, nosotros transferiremos la información, incluida la
            personal, a condición de que el destinatario se comprometa a
            respetar su información personal de conformidad con nuestra
            Declaración de privacidad.
            <br />
            <br />
            7.10. Su información y sus derechos. Puede solicitar acceso a su
            información personal, o corregir o actualizar la información
            personal desactualizada o inexacta que tenemos sobre usted. Puede
            hacerlo desde su cuenta en nuestro sitio web, donde tiene la
            posibilidad de acceder y actualizar una amplia variedad de
            información acerca de su cuenta, incluida la información de
            contacto, su información de pago y demás información relativa a su
            cuenta (como el contenido que ha visto y calificado, y sus reseñas).
            Tiene que haber iniciado sesión para acceder a su cuenta. También
            puede solicitar que eliminemos la información personal que tenemos
            sobre usted. Podemos rechazar solicitudes que no
            sean proporcionales o que la ley no exija, incluidas aquellas que
            resulten extremadamente poco prácticas, que puedan requerir un
            esfuerzo técnico desproporcionado o que podrían exponernos a riesgos
            operativos. Podemos retener la información requerida o permitida por
            las leyes y regulaciones aplicables, incluso para cumplir con sus
            selecciones, para nuestros fines de facturación o registros, y para
            cumplir con los propósitos descritos en esta Declaración de
            privacidad.
            <br />
            <br />
            7.11. Seguridad. Contamos con medidas administrativas, logísticas,
            físicas y de gestión razonables para proteger su información
            personal contra pérdidas, robos y acceso no autorizado, uso y
            modificación. Lamentablemente, ningún sistema de seguridad es 100 %
            seguro. De acuerdo con esto, no podemos garantizar la seguridad de
            su información.
            <br />
            <br />
            7.12. Cambios a esta Declaración de privacidad. Actualizaremos esta
            Declaración de privacidad cuando sea necesario, de acuerdo con los
            cambios en los requisitos legales, regulatorios u operativos.
            Avisaremos de tales cambios (incluida la información sobre la fecha
            de implementación) en conformidad con la ley. Si sigue utilizando el
            servicio de XICLOS después de que las actualizaciones entren en
            vigor, será interpretado como la aceptación de los cambios. Si no
            acepta alguna de las actualizaciones de esta Declaración de
            privacidad, puede cancelar y dejar de usar el servicio de XICLOS.
          </p>

          <p>
            8. Cookies y publicidad de Internet. <br />
            8.1. Nosotros y nuestros Proveedores de servicio utilizamos cookies
            y otras tecnologías (como señalizaciones web) por diferentes
            razones. Por ejemplo, utilizamos cookies y otras tecnologías para
            facilitar el acceso a nuestros servicios y recordarlo cuando vuelva
            a ingresar, proveer y analizar nuestros servicios, aprender más
            sobre nuestros usuarios y sus probables intereses, y presentar y
            personalizar el marketing o la publicidad.
            <br />
            <br />
            8.2. Las cookies son archivos de datos pequeños que usualmente se
            almacenan en su dispositivo cuando navega y utiliza sitios web y
            otros servicios online. Se emplean extensamente para que funcionen
            los sitios web, o para que sean más eficientes, así como para
            proveer información de reporte y ayudar con el servicio o la
            personalización de la publicidad.
            <br />
            <br />
            8.3. Las cookies no es el único tipo de tecnología que permite el
            funcionamiento; también empleamos otras tecnologías similares.
          </p>

          <p>
            9. Misceláneas <br />
            9.1. <u>Ley vigente</u>. Estos Términos de uso se regirán e
            interpretarán de conformidad con las leyes de la República
            Argentina.
            <br />
            <br />
            9.2. <u>Material no solicitado</u>. XICLOS no acepta materiales ni
            ideas no solicitados para su contenido, y no es responsable por la
            similitud entre los contenidos o la programación de cualquier medio
            con los materiales o ideas transmitidos por XICLOS.
            <br />
            <br />
            9.3. <u>Continuación de vigencia</u>. Si alguna de las disposiciones
            de estos Términos de uso es declarada nula, ilegal o inaplicable, la
            validez, legalidad y aplicación de las restantes disposiciones
            continuarán en plena vigencia.
            <br />
            <br />
            9.4. <u>Cambios a los Términos de uso y cesión</u>. XICLOS puede
            cambiar estos Términos de uso cuando sea necesario. Le informaremos
            con al menos 30 días de anticipación si estos nuevos Términos de uso
            se aplican a usted. Podemos ceder o transferir nuestro acuerdo con
            usted, incluidos nuestros derechos asociados y nuestras obligaciones
            en cualquier momento y usted acepta cooperar con nosotros en
            relación con dicha cesión o transferencia.
            <br />
            <br />
            9.5. <u>Comunicaciones electrónicas</u>. Le enviaremos la
            información relativa a su cuenta (por ejemplo, las autorizaciones de
            pago, las facturas, los cambios de contraseña o de la forma de pago,
            los mensajes de confirmación, los avisos) de manera electrónica
            únicamente, por ejemplo, mediante emails a la dirección de email
            proporcionada durante el registro.
            <br />
            <br />
            9.6. La suscripción a XICLOS implica el conocimiento y la aceptación
            de estos Términos de Uso, así como de las decisiones que adopte
            XICLOS conforme a derecho, sobre cualquier cuestión vinculada con
            ellos.
            <br />
            <br />
            9.7. Para cualquier cuestión judicial que pudiera derivarse de los
            presentes Términos y Condiciones de Uso queda establecida la
            jurisdicción de los Tribunales Ordinarios Comerciales con asiento en
            la ciudad de Buenos Aires.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
