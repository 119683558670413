import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import Firebase from "../config/Firebase";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTIONS } from "../common/Constant";

const DrawerMenu = () => {
  const usr = useSelector(state => state.usr);
  const roles = useSelector(state => state.roles);
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [showExclusivo, setShowExclusivo] = useState(false);

  useEffect(() => {
    if (usr.user) {
      const isExclusivo = usr.user.subscriptions.some(sub => {
        return (
          sub.su_name === SUBSCRIPTIONS.exclusivo ||
          sub.su_name === SUBSCRIPTIONS.exclusivoAntiguo
        );
      });
      if (isExclusivo) {
        setShowExclusivo(true);
      }
    }
  }, [usr.user]);

  const onClickItem = item => {
    if (item && item !== "logout") {
      history.push(item);
    }
    if (item && item === "logout") {
      onLogout();
    }
    setOpenMenu(false);
  };

  const onLogout = () => {
    Firebase.signOut()
      .then(() => {
        localStorage.removeItem("U");
        localStorage.removeItem("Authorization");
        // history.push("/");
        window.location.href = "/";
      })
      .catch(err => {
        console.log("algo salio mal");
      });
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        style={{ color: "white" }}>
        <MenuIcon />
      </IconButton>
      <Drawer
        open={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}>
        <MenuList style={{ minWidth: 280 }}>
          <MenuItem
            onClick={() => {
              onClickItem("/");
            }}>
            Home
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickItem("/explore");
            }}>
            Explorar
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickItem("/cine-club");
            }}>
            Cine Club
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              onClickItem("/festival");
            }}>
            Festival
          </MenuItem> */}
          {usr.user && (
            <MenuItem
              onClick={() => {
                onClickItem("/my-list");
              }}>
              Mi Lista
            </MenuItem>
          )}
          {usr.user && (
            <MenuItem
              onClick={() => {
                onClickItem("/sync-device");
              }}>
              Sincronizar Dispositivo
            </MenuItem>
          )}
          {usr.user && showExclusivo && (
            <MenuItem
              onClick={() => {
                onClickItem("/exclusive");
              }}>
              Exclusivo
            </MenuItem>
          )}
          <Divider />
          {usr.user ? (
            <div>
              <MenuItem
                onClick={() => {
                  onClickItem("/profile");
                }}>
                Mi cuenta
              </MenuItem>
              {(roles.isAdmin || roles.isSeo) && (
                <MenuItem
                  onClick={() => {
                    onClickItem("/panel-control");
                  }}>
                  Panel de control
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  onClickItem("logout");
                }}>
                Cerrar sesión
              </MenuItem>
            </div>
          ) : (
            <MenuItem
              onClick={() => {
                onClickItem("/login");
              }}>
              Login
            </MenuItem>
          )}
        </MenuList>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
