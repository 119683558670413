/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { createSubscriptionSchema } from "../schemas/validators";
import ModalMui from "../components/ModalMui";
import LoadingMsgModal from "../components/LoadingMsgModal";
import {
  getMercadoPagoPlan,
  getPayPalPlan,
  createSubscription,
} from "../provider/SubscriptionService";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  radio: {
    margin: 0,
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  spacingHorizontal: {
    marginLeft: 10,
    marginRight: 10,
  },
  modalInfoBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
});

const UploadSubscriptionType = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialValues = {
    cupon: "",
    description: "",
    priceArs: 0,
    priceUsd: 0,
    mpPlanId: "",
    paypalPlanId: "",
  };
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    message: "",
    open: false,
    callBack: () => {},
  });

  const onSubmit = async (values: any, resetForm: any) => {
    isLoading("Creando suscripción...");
    try {
      const response = await createSubscription(values);
      if (response.code === 200) {
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Suscripción creada con éxito!",
          callBack: () => {
            resetForm();
            handleCloseInfoModal();
          },
        }));
      } else {
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Ocurrió un error al crear la suscripción",
          callBack: () => {
            handleCloseInfoModal();
          },
        }));
      }
    } catch (err) {
      console.log(err);
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Ocurrió un error al crear la suscripción",
        callBack: () => {
          handleCloseInfoModal();
        },
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      callBack: () => {},
    }));
  };

  const verifyMercadoPagoPlan = async (
    url: any,
    setFieldValue: any,
    setFieldError: any,
  ) => {
    if (url === "") {
      setFieldValue("priceArs", "");
      setLoadingBtn(false);
      return;
    }
    setLoadingBtn(true);
    if (url.length > 32) {
      if (url.indexOf("preapproval_plan_id=") < 0) {
        setFieldError("mpPlanId", "Link o ID invalido");
        setFieldValue("priceArs", "");
        return;
      }
      const id = url.slice(
        url.indexOf("preapproval_plan_id=") + 20,
        url.length,
      );
      const plan = await getMercadoPagoPlan(id);
      if (plan.data === null || plan.code !== 200) {
        setFieldError("mpPlanId", "Link o ID invalido");
        setFieldValue("priceArs", "");
        setLoadingBtn(false);
        return;
      }
      setFieldValue("mpPlanId", id);
      setFieldValue("priceArs", plan.data.auto_recurring.transaction_amount);
    } else {
      if (url.length !== 32) {
        setFieldError("mpPlanId", "Link o ID invalido");
        setFieldValue("priceArs", "");
        return;
      }
      const plan = await getMercadoPagoPlan(url);
      if (plan.data === null || plan.code !== 200) {
        setFieldError("mpPlanId", "Link o ID invalido");
        setFieldValue("priceArs", "");
        setLoadingBtn(false);
        return;
      }
      setFieldValue("priceArs", plan.data.auto_recurring.transaction_amount);
    }
    setLoadingBtn(false);
  };

  const verifyPayPalPlan = async (
    url: any,
    setFieldValue: any,
    setFieldError: any,
  ) => {
    if (url === "") {
      setFieldValue("priceUsd", "");
      setLoadingBtn(false);
      return;
    }
    setLoadingBtn(true);
    if (url.length > 26) {
      let index;
      let id;
      if (url.indexOf("plan_id=") > 0) {
        index = url.indexOf("plan_id=");
        id = url.slice(index + 8, url.length);
      } else if (url.indexOf("/plans/") > 0) {
        index = url.indexOf("/plans/");
        id = url.slice(index + 7, url.length);
      } else {
        setFieldError("paypalPlanId", "Link o ID invalido");
        setFieldValue("priceUsd", "");
        return;
      }
      const plan = await getPayPalPlan(id);
      if (
        plan.data === null ||
        plan.code !== 200 ||
        !plan.data.billing_cycles
      ) {
        setFieldError("paypalPlanId", "Link o ID invalido");
        setFieldValue("priceUsd", "");
        setLoadingBtn(false);
        return;
      }
      if (plan.data.billing_cycles.length > 1) {
        setFieldValue("paypalPlanId", id);
        setFieldValue(
          "priceUsd",
          plan.data.billing_cycles[1].pricing_scheme.fixed_price.value,
        );
      } else {
        setFieldValue("paypalPlanId", id);
        setFieldValue(
          "priceUsd",
          plan.data.billing_cycles[0].pricing_scheme.fixed_price.value,
        );
      }
    } else {
      if (url.length !== 26) {
        setFieldError("paypalPlanId", "Link o ID invalido");
        setFieldValue("priceUsd", "");
        return;
      }
      const plan = await getPayPalPlan(url);
      if (
        plan.data === null ||
        plan.code !== 200 ||
        !plan.data.billing_cycles
      ) {
        setFieldError("paypalPlanId", "Link o ID invalido");
        setFieldValue("priceUsd", "");
        setLoadingBtn(false);
        return;
      }
      if (plan.data.billing_cycles.length > 1) {
        setFieldValue(
          "priceUsd",
          plan.data.billing_cycles[1].pricing_scheme.fixed_price.value,
        );
      } else {
        setFieldValue(
          "priceUsd",
          plan.data.billing_cycles[0].pricing_scheme.fixed_price.value,
        );
      }
    }
    setLoadingBtn(false);
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Agregar suscripción"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={undefined}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalMui
        open={infoModal.open}
        handleClose={() => {}}
        maxWidth="xs"
        actions={undefined}
        title={undefined}
        close={undefined}
        fullWidth={undefined}>
        <Box className={classes.modalInfoBox}>
          <Typography variant="h6" style={{ color: "black" }}>
            {infoModal.message}
          </Typography>
        </Box>
        <Box className={classes.modalInfoBox}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={infoModal.callBack}>
            Aceptar
          </Button>
        </Box>
      </ModalMui>
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={true}
              validationSchema={createSubscriptionSchema}
              onSubmit={(values, { resetForm }) => {
                const formattedValues = {
                  su_name: values.cupon,
                  su_description: values.description,
                  su_price_ars: values.priceArs,
                  su_price_usd: values.priceUsd,
                  su_mp_code: values.mpPlanId,
                  su_pay_code: values.paypalPlanId,
                };
                onSubmit(formattedValues, resetForm);
              }}>
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldError,
              }) => (
                <form
                  onSubmit={e => {
                    handleSubmit(e);
                  }}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography>Cupón</Typography>
                      <TextField
                        placeholder="Ej: SUB2022"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="cupon"
                        value={values.cupon}
                        onChange={handleChange}
                        error={errors.cupon ? true : false}
                        helperText={errors.cupon && errors.cupon}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>
                        Descripción {"(Se mostrara en el perfil)"}
                      </Typography>
                      <TextField
                        placeholder="Ej: Exclusivo alumnos"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description ? true : false}
                        helperText={errors.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Link o ID plan Mercadopago</Typography>
                      <TextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="mpPlanId"
                        onBlur={() =>
                          verifyMercadoPagoPlan(
                            values.mpPlanId,
                            setFieldValue,
                            setFieldError,
                          )
                        }
                        value={values.mpPlanId}
                        onChange={handleChange}
                        error={errors.mpPlanId ? true : false}
                        helperText={errors.mpPlanId && errors.mpPlanId}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Link o ID plan Paypal</Typography>
                      <TextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="paypalPlanId"
                        onBlur={() =>
                          verifyPayPalPlan(
                            values.paypalPlanId,
                            setFieldValue,
                            setFieldError,
                          )
                        }
                        value={values.paypalPlanId}
                        onChange={handleChange}
                        error={errors.paypalPlanId ? true : false}
                        helperText={errors.paypalPlanId && errors.paypalPlanId}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Precio mensual MercadoPago</Typography>
                      <TextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="priceArs"
                        value={values.priceArs}
                        onChange={handleChange}
                        error={errors.priceArs ? true : false}
                        helperText={errors.priceArs && errors.priceArs}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              ARS - $
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Precio mensual PayPal</Typography>
                      <TextField
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="priceUsd"
                        value={values.priceUsd}
                        onChange={handleChange}
                        error={errors.priceUsd ? true : false}
                        helperText={errors.priceUsd && errors.priceUsd}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              USD - $
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        disabled={loadingBtn}
                        type="submit"
                        className="btn btn-default btn-hover">
                        Agregar
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default UploadSubscriptionType;
