import { API, USE_MOCKS, HEADERS } from "./APIConnector";

// Define function addList
export const addList = async (id) => {
  // console.log(id);

  let user = JSON.parse(localStorage.getItem("U"));
  let userId = user.us_id;

  let request = {
    method: "POST",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fa_mo_id: id,
      fa_us_id: userId,
    }),
  };

  // Fetch
  //let api = 'http://localhost:3001/api'

  const res = await fetch(API + "/list/add", request);

  const data = await res.json();

  return data;
};

export const removeList = async (id) => {
  let user = JSON.parse(localStorage.getItem("U"));
  let userId = user.us_id;

  let request = {
    method: "DELETE",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fa_mo_id: id,
      fa_us_id: userId,
    }),
  };

  // Fetch
  //let api = 'http://localhost:3001/api'

  const res = await fetch(API + "/list/remove", request);

  const data = await res.json();

  return data;
};

export const fetchList = async () => {
  let userId = JSON.parse(localStorage.getItem("U")).us_id;
  let request = {
    method: "GET",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(API + `/list/${userId}`, request);

  const data = await res.json();

  return data;
};
