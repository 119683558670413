import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import XiclosRed from "../images/icons/xiclos-icon-red@3x.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TvIcon from "@mui/icons-material/Tv";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorIcon from "@mui/icons-material/Error";

import TheatersIcon from "@mui/icons-material/Theaters";
import BuildIcon from "@mui/icons-material/Build";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import { Helmet } from "react-helmet";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { scrollToTop } from "../common/Util";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";

import NavigationTitle from "../components/NavigationTitle";
import { Fragment } from "react";

const useStyles = makeStyles(theme => ({
  accordionTitle: {
    "& .MuiSvgIcon-root": {
      marginRight: "10px",
    },
  },
  container: {
    paddingBottom: 40,
    paddingInline: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
  },
  ul: {
    "& li": {
      marginBottom: "10px",
    },
  },
}));

const Faqs = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(function toTop() {
    let isMounted = true;
    if (isMounted) {
      scrollToTop();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Preguntas Frecuentes - Xiclos</title>
        <meta
          name="description"
          content="Preguntas frecuentes para el usuario"
        />
      </Helmet>
      <Header />
      <NavigationTitle title={"Preguntas Frecuentes"} icon={XiclosRed} />
      <div className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10}>
            <Accordion
              expanded={expanded === "registro"}
              onChange={handleChange("registro")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <AccountCircleIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo me registro a Xiclos?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Ingresa a{" "}
                  <Link
                    href="/sign-up.html"
                    color="primary"
                    target="_blank"
                    variant="button"
                    underline="hover">
                    Registro
                  </Link>{" "}
                  y completa los campos para poder suscribirte. Si tienes un{" "}
                  <b>Cupón de descuento</b>, podes ingresarlo en el campo de{" "}
                  <b>Código de promoción</b> y luego haz click en <b>Validar</b>
                  , se actualizará el monto, luego deberás completar los demás
                  campos.
                  <br />
                  <br />
                  Si eres de Argentina tu medio de pago será <b>Mercado Pago</b>
                  , de lo contrario será por medio de <b>Paypal</b>.
                  <br />
                  <br />
                  Una vez completado todos los campos y haber pasado con éxito
                  la pasarela de pago, tendrás acceso a <b>Xiclos</b> mientras
                  corroboramos el pago. En caso de que el pago haya sido{" "}
                  <b>rechazado</b>, podrás actualizar tu <b>medio de pago</b>{" "}
                  dentro de nuestra plataforma.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "samsung"}
              onChange={handleChange("samsung")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <TvIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo descargo la app de XICLOS para mi SmartTV SAMSUNG?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  En tu control remoto pulsa el botón de <b>Inicio</b>. Luego,
                  desplaza el foco hacia la izquierda hasta donde dice{" "}
                  <b>APPS</b>. Ingresa a <b>APPS</b> presionando el botón{" "}
                  <b>ENTER</b>. Se abrirá una pantalla de administración de
                  aplicaciones. Ahora busca el icono de la <b>Lupa</b>. La
                  ubicación puede diferir de la imagen anterior, dependiendo del
                  modelo de tu Smart TV. En el{" "}
                  <b>cuadro de búsqueda ingresar la palabra XICLOS</b>.
                  Ingresará a una página de instalación de la aplicación.
                  Presiona <b>INSTALAR</b> y espera hasta que la instalación
                  haya finalizado. Una vez que la aplicación se haya instalado
                  correctamente, presionar <b>ABRIR</b> para acceder a{" "}
                  <b>XICLOS</b>.<br />
                  <br />
                  Para agregar el icono de la app de <b>XICLOS</b> al menú de
                  acceso rápido o al <b>Smart Hub de su Smart TV</b>, consulta
                  el manual de usuario de tu modelo.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "lg"}
              onChange={handleChange("lg")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <TvIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo descargo la app de XICLOS para mi SmartTV LG?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Proximamente estará disponible!</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "smarttv"}
              onChange={handleChange("smarttv")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <TvIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Puedo instalarlo en mi Smart TV?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Estamos trabajando en la segunda etapa para que puedas ver{" "}
                  <b>Xiclos.com</b> en tu <b>SmartTv</b>. Actualmente la
                  plataforma se puede visualizar a través de PC, Notebooks,
                  Tablets y Celulares.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "login"}
              onChange={handleChange("login")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <BuildIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  Me registré y ahora no puedo entrar
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Si tenes un problema de acceso, puede ser por los siguientes
                  temas:
                </Typography>
              </AccordionDetails>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionTitle}>
                  <ClearIcon color="primary" />
                  <Typography
                    style={{
                      color: "var(--xiclos-bg)",
                      fontFamily: "var(--xiclos-font-helv-bold",
                    }}>
                    Contraseña no válida
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    En este caso, podes restablecer tu contraseña haciendo click
                    en{" "}
                    <Link
                      href="/login"
                      color="primary"
                      target="_blank"
                      variant="button"
                      underline="hover">
                      Login
                    </Link>{" "}
                    y hacer click en <b>¿Olvidaste tu contraseña?</b>.<br />
                    <br />
                    Si olvidaste tu contraseña e intentaste ingresar
                    reiteradamente con una contraseña errónea, el sistema
                    bloqueara tu cuenta por un tiempo determinado. Te
                    recomendamos verificar previamente tus datos de ingreso o
                    recuperar tu contraseña antes de que esto suceda.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionTitle}>
                  <ErrorIcon color="primary" />
                  <Typography
                    style={{
                      color: "var(--xiclos-bg)",
                      fontFamily: "var(--xiclos-font-helv-bold",
                    }}>
                    Otro problema de acceso
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Puedes escribirnos a <b>info@xiclos.com</b> y te
                    responderemos dentro de las 24hs
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Accordion>
            <Accordion
              expanded={expanded === "conectar"}
              onChange={handleChange("conectar")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <CastConnectedIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo conectar la PC a mi TV?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className={classes.ul}>
                  <li>
                    <Typography>
                      La manera más sencilla es usando un cable HDMI que te
                      proporcionara mejor calidad de imagen y sonido ya que el{" "}
                      <b>HDMI mejora tanto el video como el audio</b>. Este tipo
                      de cable, lo podes encontrar en cualquier gran superficie
                      o <b>tienda de informática</b>.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Encende el televisor y el portátil. Busca en la parte
                      trasera o en los laterales del televisor{" "}
                      <b>una conexión que venga marcada con HDMI</b>.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Conecta tu cable HDMI macho en la conexión de tu televisor
                      (que es tipo hembra). A continuación repeti la misma
                      operación pero en tu ordenador portátil. Busca en los
                      laterales la conexión HDMI e{" "}
                      <b>
                        introduci el otro conector en su ranura correspondiente
                      </b>
                      .
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Busca en el mando del televisor la opción para cambiar la
                      entrada suele ser botón <b>"Source"</b> o <b>"Input"</b> y{" "}
                      <b>te saldrá un menú donde podes elegir HDMI</b>.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "buscar"}
              onChange={handleChange("buscar")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <SearchIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo puedo buscar películas?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  En nuestro buscador dinámico podrás realizar búsquedas por
                  diferentes filtros: títulos de películas, directores, actores,
                  etc.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "nuevas"}
              onChange={handleChange("nuevas")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <TheatersIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Con qué frecuencia suben nuevas películas?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Estamos subiendo por semana a la plataforma de{" "}
                  <b>Xiclos.com</b> entre <b>4 a 5 estrenos</b>, clásicos, cine
                  del mundo, culto, entre muchos mas.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "cancelar"}
              onChange={handleChange("cancelar")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionTitle}>
                <ClearIcon color="primary" />
                <Typography
                  style={{
                    color: "var(--xiclos-bg)",
                    fontFamily: "var(--xiclos-font-helv-bold",
                  }}>
                  ¿Cómo cancelo mi membresía?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  En <b>Mi Cuenta</b> en la sección <b>Membresía</b> haces click
                  en el botón <b>Cancelar mi suscripción</b>. Ante cualquier
                  inconveniente puedes comunicarte con nosotros al correo:{" "}
                  <b>info@xiclos.com</b>.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Faqs;
