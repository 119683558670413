import { ACT_USER_CHANGE } from "../actions/ActionTypes";

const initialState = {
  user: null,
  payment: null,
  token: null,
  devices: [],
  checked: false,
  isAdmin: null,
  isSeo: null,
};

export default function (
  state = initialState,
  action: { type: string; props: any },
) {
  if (action.type === ACT_USER_CHANGE) {
    return { ...state, ...action.props };
  }

  return state;
}
