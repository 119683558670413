import {
  ACT_IS_LOADING_APP,
  ACT_IS_NOT_LOADING_APP,
} from "../actions/ActionTypes";

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action: { type: any }) {
  switch (action.type) {
    case ACT_IS_LOADING_APP:
      return {
        ...state,
        isLoading: true,
      };
    case ACT_IS_NOT_LOADING_APP:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
