import React, { useState, memo, useRef, useEffect } from "react";
import { getDeviceType } from "../common/Util";
import { LARGE_DEVICE } from "../common/Constant";
import Loader from "./Loader";
import { trimString } from "../common/Util";

import {
  addMovieListAction,
  removeMovieListAction,
} from "../actions/MyListAction.js";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const Poster = ({ item, isEmpty, index, handleModal, freeMode }) => {
  const [loading, setLoading] = useState(true);
  let image = useRef();
  let ml = useSelector(state => state.ml);
  let usr = useSelector(state => state.usr);
  let history = useHistory();
  const dispatch = useDispatch();

  const isInMyList = id => {
    const isInList = ml.movies.filter(movie => {
      if (movie.mo_id === id) {
        return movie;
      } else {
        return false;
      }
    });
    if (isInList.length === 0) {
      return false;
    }
    return true;
  };

  const onLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (image && image.current && image.current.complete) {
      onLoad();
    }
  });

  return (
    <div
      className="vortex-film"
      onClick={
        getDeviceType() === LARGE_DEVICE
          ? null
          : () => {
              usr.user
                ? history.push({
                    pathname: "/details",
                    search: `?id=${item.mo_id}`,
                    state: {
                      title: item.mo_title_metadata
                        ? item?.mo_title_metadata?.slice(0, 59)
                        : item?.mo_title?.slice(0, 59),
                      description: item.mo_description_metadata
                        ? item?.mo_description_metadata?.slice(0, 159)
                        : item?.mo_description?.slice(0, 159),
                    },
                  })
                : freeMode
                ? history.push({
                    pathname: "/details-festival",
                    search: `?id=${item.mo_id}`,
                    state: {
                      title: item.mo_title_metadata
                        ? item?.mo_title_metadata?.slice(0, 59)
                        : item?.mo_title?.slice(0, 59),
                      description: item.mo_description_metadata
                        ? item?.mo_description_metadata?.slice(0, 159)
                        : item?.mo_description?.slice(0, 159),
                    },
                  })
                : history.push({
                    pathname: "/details",
                    search: `?id=${item.mo_id}`,
                    state: {
                      title: item.mo_title_metadata
                        ? item?.mo_title_metadata?.slice(0, 59)
                        : item?.mo_title?.slice(0, 59),
                      description: item.mo_description_metadata
                        ? item?.mo_description_metadata?.slice(0, 159)
                        : item?.mo_description?.slice(0, 159),
                    },
                  });
            }
      }>
      <div className="vortex-film-img">
        {item && item.mo_thumbnail && (
          <img
            src={
              getDeviceType() === LARGE_DEVICE
                ? item.mo_thumbnail.replace("_150x260", "")
                : item.mo_thumbnail
            }
            className="vortex-film-banner"
            alt={item.mo_title}
            ref={image}
            onLoad={onLoad}
          />
        )}
        <Loader show={loading} />
      </div>
      {getDeviceType() === LARGE_DEVICE && (
        <div className="vortex-film-description">
          <div className="vortex-no-scale p-3 mx-auto">
            <div className="row vortex-description-container">
              <div className="d-none d-md-flex col-md-5 vortex-description-img pr-3 pl-0">
                <img src={item.mo_thumbnail} alt={item.mo_title} />
              </div>
              <div className="col-sm-12 col-md-7 px-0">
                <h2 className="vortex-description-title text-uppercase">
                  {item.mo_title}
                </h2>
                <div className="vortex-description-taxs">
                  <div className="vortex-description-tax">
                    <h3 className="vortex-description-tax-text">
                      <span className="vortex-description-tax-title">
                        Origen{" "}
                      </span>
                      <span className="vortex-description-tax-options">
                        {item.countries}
                      </span>
                    </h3>
                  </div>
                  <div className="vortex-description-tax">
                    <h3 className="vortex-description-tax-text">
                      <span className="vortex-description-tax-title">
                        Director{" "}
                      </span>
                      <span className="vortex-description-tax-options">
                        {trimString(item.directors, 50)}
                      </span>
                    </h3>
                  </div>
                  <div className="vortex-description-tax">
                    <h3 className="vortex-description-tax-text">
                      <span className="vortex-description-tax-title">
                        Reparto{" "}
                      </span>
                      <span className="vortex-description-tax-options">
                        {trimString(item.actors, 50)}
                      </span>
                    </h3>
                  </div>
                  <div className="vortex-description-tax">
                    <h3 className="vortex-description-tax-text">
                      <span className="vortex-description-tax-title">
                        Género{" "}
                      </span>
                      <span className="vortex-description-tax-options">
                        {trimString(item.genre, 24)}
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="row d-flex align-items-center mt-2">
                  <div className="col-8 px-0">
                    <button
                      className="btn btn-hover vortex-description-see-movie"
                      onClick={() => {
                        usr.user
                          ? history.push({
                              pathname: "/watch",
                              search: `?id=${item.mo_id}`,
                            })
                          : freeMode
                          ? history.push({
                              pathname: "/watch-festival",
                              search: `?id=${item.mo_id}`,
                            })
                          : history.push("/login");
                      }}>
                      <i className="fa fa-play mr-1" aria-hidden="true"></i>
                      VER PELICULA
                    </button>
                  </div>
                  <div className="col-4 px-0">
                    <Link
                      to={{
                        pathname: freeMode ? `/details-festival` : `/details`,
                        search: `?id=${item.mo_id}`,
                        state: {
                          title: item.mo_title_metadata
                            ? item?.mo_title_metadata?.slice(0, 59)
                            : item?.mo_title?.slice(0, 59),
                          description: item.mo_description_metadata
                            ? item?.mo_description_metadata?.slice(0, 159)
                            : item?.mo_description?.slice(0, 159),
                        },
                      }}>
                      <button
                        onClick={() => {
                          // scrollToTop();
                        }}
                        className="vortex-description-see-more">
                        Ver más
                      </button>
                    </Link>
                  </div>
                </div>
                {usr.user ? (
                  isInMyList(item.mo_id) ? (
                    <div className="row">
                      <div className="col-12 d-flex align-items-center px-0 mt-3">
                        <button
                          className="vortex-description-add-fav d-flex justify-content-left align-items-center pl-2 py-1 pr-3"
                          onClick={() => {
                            dispatch(
                              removeMovieListAction(
                                item,
                                () => handleModal("remove"),
                                () => handleModal("errorRemove"),
                              ),
                            );
                          }}>
                          <i className="ri-subtract-line pr-2"></i>
                          Eliminar de mi lista
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12 d-flex align-items-center px-0 mt-3">
                        <button
                          className="vortex-description-add-fav d-flex justify-content-left align-items-center pl-2 py-1 pr-3"
                          onClick={() => {
                            dispatch(
                              addMovieListAction(
                                item,
                                () => handleModal("add"),
                                () => handleModal("errorAdd"),
                              ),
                            );
                          }}>
                          <i className="ri-add-line pr-2"></i>
                          Añadir a mi lista
                        </button>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0">
                <h6 className="vortex-description-sinopsis">Sinopsis</h6>
                <p className="vortex-description-sinopsis-text">
                  {trimString(item.mo_description, 115)}
                </p>
              </div>
            </div>
            <div className="vortex-description-features">
              <div className="d-flex justify-content-between align-items-center">
                <div className="vortex-description-times d-flex justify-content-left align-items-center">
                  <p className="vortex-description-tag">{item.mo_year}</p>
                  <p className="vortex-description-tag">
                    {item.mo_duration} min
                  </p>
                  <p className="vortex-description-pg">{item.mo_mpa_rating}</p>
                </div>
                <p className="vortex-description-imdb">
                  IMDb {item.mo_imdb_rating}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Poster);
