import { API, HEADERS } from "./APIConnector";

export const fetchGenres = async ({ type, minMovies }) => {
  let request = {
    method: "GET",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    API +
      `/genres?${type ? `type=${type}` : ""}${
        minMovies ? `&minMovies=${minMovies}` : ""
      }`,
    request,
  );
  const data = await response.json();

  return data;
};

export const createGenre = async body => {
  const response = await fetch(API + "/genre", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("Authorization"),
      U: localStorage.getItem("U"),
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const fetchAllGenres = async () => {
  const response = await fetch(`${API}/genre`, {
    method: "GET",
    headers: HEADERS(),
  });
  return await response.json();
};

export const deleteGenre = async body => {
  const response = await fetch(`${API}/delete/genre`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ ge_id: body }),
  });
  return await response.json();
};
