import React, { useState } from "react";

import "../css/login.scss";

import { useHistory, Link } from "react-router-dom";
import firebase from "firebase";
import { useQuery } from "../hooks/useQuery";
import { useEffect } from "react";
import FooterLogin from "../components/FooterLogin";
import { Helmet } from "react-helmet";

const Login = () => {
  const history = useHistory();
  const query = useQuery();
  const [error, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    remember: false,
  });

  useEffect(() => {
    if (query.get("email")) {
      setValues(prevState => ({ ...prevState, email: query.get("email") }));
    }
  }, [query]);

  const onSubmit = event => {
    event.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdToken(values.remember)
          .then(token => {
            setLoading(false);
            localStorage.setItem("Authorization", token);
            localStorage.setItem("is", "checked");
            localStorage.setItem("remember", "true");
            // history.push("/home");
            window.location.href = "/home";
          });
      })
      .catch(err => {
        console.log(err);
        switch (err.code) {
          case "auth/wrong-password":
            setError({
              error: true,
              message: "*Las credenciales son incorrectas",
            });
            break;
          case "auth/too-many-requests":
            setError({
              error: true,
              message: "Demasiados intentos, intente de nuevo en unos segundos",
            });
            break;
          default:
            setError({ error: true, message: "Ocurrió un error inesperado" });
            break;
        }
        setLoading(false);
      });
  };

  const handleChange = event => {
    setError({ ...error, error: false });
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setValues({
      ...values,
      [event.target.name]: value,
    });
  };

  return (
    <div className="login">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Xiclos</title>
        <meta name="description" content="Página de ingreso de usuario" />
      </Helmet>
      <div id="sign-in">
        <section
          className="sign-in-page animate__animated animate__fadeIn"
          // style={{ minHeight: 0 }}
        >
          <div id="sign-in-back">
            <i
              className="ri-arrow-left-line"
              onClick={() => history.goBack()}></i>
          </div>
          <div className="sign-in-page-backdrop">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <div
                  className="col-lg-5 col-md-12 "
                  style={{ paddingTop: "5em", paddingBottom: "65px" }}>
                  <div className="sign-user_card sign-user-card-style-data animate__animated animate__backInUp">
                    <div className="sign-in-page-data">
                      <div className="sign-in-from w-100 m-auto">
                        <h3 className="mb-3 text-center sign-title">
                          Iniciar sesión
                        </h3>
                        <p className="sign-in-page-p">Ingresá tus datos</p>
                        <form className="mt-4" onSubmit={onSubmit}>
                          <div className="form-group">
                            <input
                              name="email"
                              type="email"
                              onChange={handleChange}
                              value={values.email}
                              className="form-control mb-0 sign-user-card-style-data-form"
                              id="sign-in-email"
                              placeholder="Email"
                              autoComplete="on"
                              autoFocus
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              type="password"
                              className="form-control mb-0 sign-user-card-style-data-form"
                              id="sign-in-password"
                              placeholder="Contraseña"
                              required
                            />
                          </div>

                          <div className="sign-info">
                            <div className="custom-control custom-checkbox d-inline-block">
                              <input
                                onChange={handleChange}
                                checked={values.remember}
                                name="remember"
                                type="checkbox"
                                className="custom-control-input"
                                id="sign-in-checkbox"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sign-in-checkbox">
                                Recordarme
                              </label>
                            </div>
                            <Link to="/reset-password" className="text-primary">
                              ¿Olvidaste tu contraseña?
                            </Link>
                          </div>
                          <div className="mt-3">
                            <button
                              type="submit"
                              id="sign-in-submit"
                              className="btn btn-hover">
                              {!loading && !error.error ? (
                                "INICIAR SESIÓN"
                              ) : error.error ? (
                                ":("
                              ) : (
                                <div>
                                  <i className="ri-loader-4-line" />
                                </div>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div
                        id="sign-in-errors"
                        style={{
                          color: "var(--iq-primary)",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="animate__animated animate__fadeIn">
                        {error.error && <p>{error.message}</p>}
                      </div>
                      <div className="d-flex justify-content-center links">
                        ¿No sos parte de Xiclos?{" "}
                        <a href="sign-up.html" className="text-primary ml-2">
                          Regístrate aquí
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-none d-lg-flex justify-content-center align-items-end sign-up-xiclos flex-column">
                  <img src="../images/logo.png" alt="" />
                  <h1 className="text-right h1 my-3">
                    Comenzá a disfrutar del mejor cine
                  </h1>
                  <h2 className="text-right h2">EL OTRO CINE</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLogin
          className="footer-contact"
          copyRightText={`Copyright © 2021 - XICLOS - Todos los derechos reservados.`}
        />
      </div>
    </div>
  );
};

export default Login;
