import React from "react";
import Director from "./Director";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import useWidth from "../hooks/useWidth";

SwiperCore.use([Autoplay, Navigation, Pagination, Virtual]);

const renderDirector = (director, idx) => {
  return (
    <SwiperSlide key={"dm" + idx}>
      <Director director={director} />
    </SwiperSlide>
  );
};

const DirectorCarousel = props => {
  const { directors } = props;
  const width = useWidth();
  const swiperProps = {
    freeMode: width === "xs" || width === "sm" ? false : true,
    autoplay: true,
    loop: true,
    navigation: width === "xs" || width === "sm" ? false : true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 3,
        // slidesPerGroup: 3,
        spaceBetween: 2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 2,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 2,
      },
    },
  };

  return (
    <Swiper
      {...swiperProps}
      style={{ paddingLeft: 40, paddingRight: 40 }}
      // onSlideChange={onSlideChange}
    >
      {directors && directors.map(renderDirector)}
    </Swiper>
  );
};

export default DirectorCarousel;
