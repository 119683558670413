import { ACT_BANNER_CHANGE } from "../actions/ActionTypes";

const initialState = {
  loadingBanners: true,
  banners: [],
};

export default function (
  state = initialState,
  action: { type: string; props: any },
) {
  if (action.type === ACT_BANNER_CHANGE) {
    return Object.assign({}, state, action.props);
  }
  return state;
}
