import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";

import { getExclusiveMovies } from "../provider/ExclusiveServices";
import { useSelector, useDispatch } from "react-redux";
import { getListByUser } from "../provider/SliderServices";
import { listAction } from "../actions/MyListAction";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import SliderPull from "../components/SliderPull";
import ScrollButton from "../components/ScrollToTop";
import { getMovieData } from "../common/Util";
import { Helmet } from "react-helmet";

const ExclusiveSection = () => {
  const ml = useSelector(state => state.ml);
  const dispatch = useDispatch();
  const [exclusiveMovies, setExclusiveMovies] = useState({ movies: [] });
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (ml.count_movies === 0) {
      getListByUser()
        .then(result => {
          if (result.count_movies !== ml.count_movies) {
            dispatch(listAction(result));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [dispatch, ml.count_movies]);

  useEffect(() => {
    getExclusiveMovies()
      .then(result => {
        const movies = result.data.map(exclusives => {
          return getMovieData(exclusives.Movie);
        });
        setExclusiveMovies({ movies: movies });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = action => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div id="cineclub">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Exclusivo - Xiclos</title>
        <meta
          name="description"
          content="Zona exclusivos para usuarios específicos"
        />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Exclusivo"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <SliderPull
        id={"vortex-recommend"}
        slider={exclusiveMovies}
        handleModal={handleModal}
      />
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ExclusiveSection;
