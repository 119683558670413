import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import SliderReducer from "./SliderReducer";
import BannerReducer from "./BannerReducer";
import LoadingReducer from "./LoadingReducer";
import CineClubReducer from "./CineClubReducer";
import RolesReducer from "./RolesReducer";
import MyListReducer from "./MyListReducer";

const rootReducer = combineReducers({
  usr: UserReducer,
  bn: BannerReducer,
  sl: SliderReducer,
  ml: MyListReducer,
  cineClub: CineClubReducer,
  roles: RolesReducer,
  loading: LoadingReducer,
});

export default rootReducer;
