import React from "react";

import "../css/panel-control.scss";

import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Paper, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const PanelControl = () => {
  const roles = useSelector(state => state.roles);

  const SeoOptions = () => {
    return (
      <Grid item xs={12} md={10} lg={8}>
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                SEO
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <Link
              to="/panel-control/seo/edit-movie"
              className="list-group-item list-group-item-action option-hover">
              Editar películas
            </Link>
          </div>
        </Paper>
        <hr />
      </Grid>
    );
  };

  const AdminOptions = () => {
    return (
      <Grid item xs={12} md={10} lg={8}>
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                SEO
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <Link
              to="/panel-control/seo/edit-movie"
              className="list-group-item list-group-item-action option-hover">
              Editar películas
            </Link>
          </div>
        </Paper>
        <hr />
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                Notificaciones
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <Link
              to="/panel-control/notifications"
              className="list-group-item list-group-item-action option-hover">
              Ver Notificaciones
            </Link>
          </div>
        </Paper>
        <hr />
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                Seleccione que desea agregar
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <a
              href="upload-banner.html"
              className="list-group-item list-group-item-action option-hover">
              Agregar banner
            </a>
            {/* <Link
            to="/panel-control/upload-banner"
            className="list-group-item list-group-item-action option-hover">
            Agregar banner
          </Link> */}
            <Link
              to="/panel-control/upload-genre"
              className="list-group-item list-group-item-action option-hover">
              Agregar género
            </Link>
            <a
              href="upload-movie.html"
              className="list-group-item list-group-item-action option-hover">
              Agregar película
            </a>
            {/* <Link
                  to="/upload-movie"
                  className="list-group-item list-group-item-action option-hover">
                  Agregar película
                </Link> */}
            <a
              href="upload-slider.html"
              className="list-group-item list-group-item-action option-hover">
              Agregar tira
            </a>
            {/* <Link
                  to="/upload-slider"
                  className="list-group-item list-group-item-action option-hover">
                  Agregar tira
                </Link> */}
            <Link
              to="/panel-control/upload-user-free"
              className="list-group-item list-group-item-action option-hover">
              Agregar usuario <b>FREE</b>
            </Link>
            <Link
              to="/panel-control/upload-subscription"
              className="list-group-item list-group-item-action option-hover">
              Agregar suscripción
            </Link>
          </div>
        </Paper>
        <hr />
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                Seleccione que desea editar
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <a
              href="edit-banner.html"
              className="list-group-item list-group-item-action option-hover">
              Editar banner
            </a>
            {/* <Link
                  to="/edit-banner"
                  className="list-group-item list-group-item-action option-hover">
                  Editar banner
                </Link> */}
            <a
              href="edit-genre.html"
              className="list-group-item list-group-item-action option-hover">
              Editar género
            </a>
            {/* <Link
                  to="/edit-genre"
                  className="list-group-item list-group-item-action option-hover">
                  Editar género
                </Link> */}
            <a
              href="edit-movie.html"
              className="list-group-item list-group-item-action option-hover">
              Editar película
            </a>
            {/* <Link
                  to="/edit-movie"
                  className="list-group-item list-group-item-action option-hover">
                  Editar película
                </Link> */}
            <a
              href="edit-slider-order.html"
              className="list-group-item list-group-item-action option-hover">
              Editar orden tiras
            </a>
            {/* <Link
                  to="/edit-slider-order"
                  className="list-group-item list-group-item-action option-hover">
                  Editar orden tiras
                </Link> */}
            <a
              href="edit-slider.html"
              className="list-group-item list-group-item-action option-hover">
              Editar tira
            </a>
            {/* <Link
            to="/panel-control/edit-slider"
            className="list-group-item list-group-item-action option-hover">
            Editar tira
          </Link> */}
            <Link
              to="/panel-control/users"
              className="list-group-item list-group-item-action option-hover">
              Editar usuarios
            </Link>
            <Link
              to="/panel-control/edit-subscriptions"
              className="list-group-item list-group-item-action option-hover">
              Editar suscripciones
            </Link>
          </div>
        </Paper>
        <hr />
        <Paper className="paper-content">
          <div className="row">
            <div className="sign-up-header" style={{ marginBottom: "1em" }}>
              <p
                className="mb-0"
                style={{
                  fontFamily: "Helvetica Neue Bold",
                  color: "#343433",
                  fontSize: "1em",
                }}>
                Seleccione que desea eliminar
              </p>
            </div>
          </div>

          <div className="list-group" style={{ textAlign: "center" }}>
            <Link
              to="/panel-control/delete-banner"
              className="list-group-item list-group-item-action option-hover">
              Eliminar banner
            </Link>
            <Link
              to="/panel-control/delete-genre"
              className="list-group-item list-group-item-action option-hover">
              Eliminar genero
            </Link>
            <Link
              to="/panel-control/delete-movie"
              className="list-group-item list-group-item-action option-hover">
              Eliminar película
            </Link>
            <Link
              to="/panel-control/delete-slider"
              className="list-group-item list-group-item-action option-hover">
              Eliminar tira
            </Link>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <div className="panel-control">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Panel de control - Xiclos</title>
        <meta name="description" content="Panel de control de la aplicación." />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Panel de control"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <Grid container justifyContent="center" className="paper-container">
        {roles.isAdmin ? <AdminOptions /> : roles.isSeo && <SeoOptions />}
      </Grid>
      <Footer />
    </div>
  );
};

export default PanelControl;
