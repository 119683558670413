import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Cards from "@snowpak/react-credit-cards";

import "@snowpak/react-credit-cards/es/styles-compiled.css";
import { validateCreditCard } from "../provider/ProfileServices";
// import useScript from '../hooks/useScript';
import { updatePayment } from "../provider/UserService";
import Loader from "./Loader";
import { getMercadoPagoInstance } from "../config/MercadoPago";

const useStyles = makeStyles(theme => ({
  input: {},
  card: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  gridInputs: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  select: {
    minWidth: "25ch",
  },
  formControl: {
    margin: "10px 0",
  },
}));

const PaymentForm = ({ method = "", user, submit, cancel }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [creditCard, setCreditCard] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    typeDoc: "",
    doc: "",
    issuerId: "",
    paymentMethod: "",
    validateBin: false,
  });
  const [errors, setErrors] = useState({
    cvc: { msg: "", status: false },
    expiry: { msg: "", status: false },
    name: { msg: "", status: false },
    number: { msg: "", status: false },
    doc: { msg: "", status: false },
    all: { msg: "", status: false },
  });
  const [typeDocuments, setTypeDocuments] = useState([]);
  // const { MercadoPago } = useScript(
  //     "https://sdk.mercadopago.com/js/v2",
  //     "MercadoPago"
  // );
  const [mp, setMp] = useState(null);

  useEffect(() => {
    if (!mp) {
      setLoading(true);
      getMercadoPagoInstance().then(mpInstance => {
        setMp(mpInstance);
        setLoading(false);
      });
    }
  }, [mp]);

  useEffect(() => {
    if (mp && method === "MercadoPago" && typeDocuments.length === 0) {
      // let mp = new MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {locale: 'es-AR'})
      let arr = [];
      mp.getIdentificationTypes().then(values => {
        values.forEach(el => {
          arr.push({ id: el.id, name: el.name });
        });
        setTypeDocuments(arr);
      });
    }
  }, [mp, method]);

  const handleInputFocus = e => {
    let { name } = e.target;
    name = name ? name : "";
    setCreditCard(state => ({ ...state, focus: name }));
  };

  const handleInputChange = e => {
    let { name, value } = e.target;
    value = value ? value : "";
    setErrors(state => ({ ...state, [name]: { status: false, msg: "" } }));
    if (name === "name") {
      setCreditCard(state => ({ ...state, [name]: value }));
    } else if (name === "number") {
      setCreditCard(state => ({
        ...state,
        [name]: value.split(" ").join("").split("/").join(""),
      }));
      if (value.length >= 6 && !creditCard.validateBin) {
        // let mp = new MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {locale: 'es-AR'})
        const bin = value.substring(0, 6);
        // console.log("GET PAYMENT METHOD", mp);
        mp.getPaymentMethods({
          bin: bin,
        }).then(paymentMethod => {
          let pm =
            paymentMethod && paymentMethod.results[0]
              ? paymentMethod.results[0]
              : { issuer: { id: null }, id: null };
          setCreditCard(state => ({
            ...state,
            issuerId: pm.issuer.id,
            paymentMethod: pm.id,
            validateBin: true,
          }));
          // console.log("paymentMethod", paymentMethod)
        });
      }
    } else {
      setCreditCard(state => ({
        ...state,
        [name]: value.split(" ").join("").split("/").join(""),
      }));
    }
  };

  const handleSubmitMP = async e => {
    e.preventDefault();
    // console.log('HandleSubmitMP');
    setLoading(true);
    let errs = validateCreditCard(creditCard);
    // console.log(errs);
    if (errs) {
      // console.log('Error validate cc: ', errs);
      let arr = Object.entries(errs);
      // console.log(arr);
      arr.forEach(el => {
        let name = el[0];
        let msg = el[1];
        setErrors(state => ({ ...state, [name]: { status: true, msg } }));
      });
      setLoading(false);
    } else {
      // let mp = new MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {locale: 'es-AR'})
      const createTokenParams = {
        cardNumber: creditCard.number.trim(),
        cardholderName: creditCard.name,
        cardExpirationMonth: `${creditCard.expiry.substring(0, 2)}`,
        cardExpirationYear: `20${creditCard.expiry.substring(2, 4)}`,
        securityCode: creditCard.cvc,
        identificationType: creditCard.typeDoc,
        identificationNumber: creditCard.doc,
      };

      let cardToken = await mp.createCardToken(createTokenParams);

      let body = {
        token: cardToken,
        issuer_id: creditCard.issuerId,
        payment_method_id: creditCard.paymentMethod,
        transaction_amount: Number(user.amount.substring(1)),
        installments: Number(1),
        description: "Xiclos",
        payer: {
          email: user.email,
          identification: {
            type: creditCard.typeDoc,
            number: creditCard.doc,
          },
        },
      };

      let result = await updatePayment(body);
      let data = result.data;

      if (data) {
        if (data.status === "authorized") {
          submit(null, {
            msg: "El medio de pago ha sido actualizado, mientras lo corroboramos tu suscripción estará activa!",
            status: 200,
            title: "Medio de pago actualizado",
          });
        } else {
          submit(null, {
            msg: data.message,
            status: 400,
            title: "Pago rechazado",
          });
        }
      } else {
        submit(null, {
          msg: "El medio de pago no se procesó, te pedimos que vuelvas a intentarlo mas tarde",
          status: 500,
          title: "Hubo un problema",
        });
        console.log("500 internal server error");
      }
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ height: 250, width: 250 }}>
        <Loader show={loading} />
      </div>
    );
  }

  if (user.subscription === null) {
    return (
      <form method="POST" action="">
        <Grid container>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "var(--xiclos-font-helv-bold)",
              color: "var(--xiclos-red)",
              padding: "20px 0",
            }}>
            No existe subscripción asociada
          </Typography>
          <Grid item xs={12}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              onClick={cancel}
              fullWidth>
              Volver
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  // ["h1","h2","h3","h4","h5","h6","subtitle1","subtitle2","body1","body2","caption","button","overline","srOnly","inherit"]
  if (method === "MercadoPago") {
    return (
      <form id="payment-form" method="POST" action="" onSubmit={handleSubmitMP}>
        <Grid container spacing={0}>
          <label style={{ padding: "0 0 20px 0" }}>
            Completa los datos de tu tarjeta de débito o crédito a continuación:
          </label>
          <Grid item sm={12} md={6} className={classes.card}>
            <Cards
              cvc={creditCard.cvc}
              expiry={creditCard.expiry}
              focused={creditCard.focus}
              name={creditCard.name}
              number={creditCard.number}
              placeholders={{ name: "NOMBRE COMPLETO" }}
              locale={{ valid: "mes / año" }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "30px 0",
              }}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-text)",
                }}>
                Membresía
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-red)",
                }}>
                {user.membership}
              </Typography>
              <span
                style={{
                  fontFamily: "var(--xiclos-font-helv-medium)",
                  color: "var(--xiclos-text)",
                }}>
                Monto a abonar:
              </span>
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "var(--xiclos-font-helv-bold)",
                  color: "var(--xiclos-text)",
                }}>
                {" "}
                {user.amount}/mes
              </Typography>
            </div>
          </Grid>

          <Grid item sm={12} md={6} className={classes.gridInputs}>
            <TextField
              margin="dense"
              name="number"
              type="tel"
              label="Número de tarjeta"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              error={errors.number.status}
              helperText={errors.number.status && errors.number.msg}
              required
            />
            <TextField
              margin="dense"
              name="expiry"
              type="number"
              label="Vencimiento"
              variant="outlined"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              error={errors.expiry.status}
              helperText={errors.expiry.status && errors.expiry.msg}
              required
            />
            <TextField
              margin="dense"
              name="cvc"
              type="number"
              label="Código CVC"
              variant="outlined"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              error={errors.cvc.status}
              helperText={errors.cvc.status && errors.cvc.msg}
              required
            />
            <TextField
              margin="dense"
              name="name"
              type="text"
              label="Nombre como figura en la tarjeta"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              error={errors.name.status}
              helperText={errors.name.status && errors.name.msg}
              required
            />
            {typeDocuments.length > 0 && (
              <FormControl required margin="dense" variant="outlined">
                <InputLabel id="payment-form-select-label">
                  Tipo de documento
                </InputLabel>
                <Select
                  labelId="payment-form-select-label"
                  id="payment-form-select"
                  label="Tipo de documento"
                  className={classes.select}
                  name="typeDoc"
                  value={creditCard.typeDoc}
                  onChange={handleInputChange}
                  required>
                  {typeDocuments.map(el => (
                    <MenuItem key={el.id} value={el.name}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              margin="dense"
              name="doc"
              type="number"
              variant="outlined"
              label="Número de documento"
              onChange={handleInputChange}
              error={errors.doc.status}
              helperText={errors.doc.status && errors.doc.msg}
              required
            />
            <FormControlLabel
              control={<Checkbox required />}
              label="Entiendo que el cobro se debitará todos los meses automáticamente de mi tarjeta."
            />
          </Grid>
          <Grid item xs={4} sm={6}>
            <Button color="primary" disableElevation fullWidth onClick={cancel}>
              Volver
            </Button>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Button
              type="submit"
              disableElevation
              fullWidth
              color="primary"
              variant="contained">
              Cambiar método
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  return <div>Aún no está desarrollado</div>;
};

export default PaymentForm;
