export const ACT_USER_CHANGE = "ACT_USER_CHANGE";
export const ACT_SLIDER_COUNT = "ACT_SLIDER_COUNT";
export const ACT_SLIDER_INIT = "ACT_SLIDER_INIT";
export const ACT_SLIDER_CHANGE = "ACT_SLIDER_CHANGE";
export const ACT_SLIDER_UPDATE = "ACT_SLIDER_UPDATE";
export const ACT_SLIDER_DIRECTORS = "ACT_SLIDER_DIRECTORS";
export const ACT_BANNER_CHANGE = "ACT_BANNER_CHANGE";
export const ACT_MYLIST_CHANGE = "ACT_MYLIST_CHANGE";
export const ACT_MYLIST_ADDMOVIE = "ACT_MYLIST_ADDMOVIE";
export const ACT_MYLIST_REMOVE = "ACT_MYLIST_REMOVE";
export const ACT_IS_ADMIN_TRUE = "ACT_IS_ADMIN_TRUE";
export const ACT_IS_ADMIN_FALSE = "ACT_IS_ADMIN_FALSE";
export const ACT_IS_SEO_TRUE = "ACT_IS_SEO_TRUE";
export const ACT_IS_SEO_FALSE = "ACT_IS_SEO_FALSE";

export const ACT_CINECLUB_FETCH_MOVIES = "ACT_CINECLUB_FETCH_MOVIES";

export const ACT_IS_LOADING_APP = "ACT_IS_LOADING_APP";
export const ACT_IS_NOT_LOADING_APP = "ACT_IS_NOT_LOADING_APP";
