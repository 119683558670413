import React, { useEffect, useState, useCallback } from "react";
import "../css/home-no-user.scss";
import Footer from "../components/Footer";
import { LoaderApp } from "../components/Loader";
import { useHistory, Link } from "react-router-dom";
import { getMoviesBySliderId } from "../provider/SliderServices.js";
import Loader from "../components/Loader";
import { HashLink } from "react-router-hash-link";
import SliderPull from "../components/SliderPull";
import CyanXiclos from "../images/icons/xiclos-icon-cyan.png";
import YellowXiclos from "../images/icons/xiclos-icon-yellow.png";
import { Helmet } from "react-helmet";

export const HomeNoUser = props => {
  const history = useHistory();
  const [moviesCatalogo, setMoviesCatalogo] = useState({ movies: [] });
  const [moviesClasicos, setMoviesClasicos] = useState({ movies: [] });
  // const [moviesFestival, setMoviesFestival] = useState({ movies: [] });

  const getSliderDataArr = useCallback(item => {
    let directorsArray = [];
    item.directors.forEach(item => {
      directorsArray.push(item.di_name + " " + item.di_lastname);
    });
    let directors = "";
    if (directorsArray.length > 0) {
      directors = directorsArray.join(", ");
    }

    let genreArray = [];
    item.genre.forEach(item => {
      genreArray.push(item.ge_name);
    });
    let genre = "";

    if (genreArray.length > 0) {
      genre = genreArray.join(", ");
    }

    let actorArray = [];
    item.actor.forEach(item => {
      actorArray.push(item.ac_name + " " + item.ac_lastname);
    });
    let actors = "";
    if (actorArray.length > 0) {
      actors = actorArray.join(", ");
    }

    let countriesArray = [];
    item.country.forEach(item => {
      countriesArray.push(item.co_name);
    });
    let countries = "";
    if (countriesArray.length > 0) {
      countries = countriesArray.join(", ");
    }

    const data = {
      ...item,
      directors: directors,
      genre: genre,
      actors: actors,
      countries: countries,
    };

    return data;
  }, []);

  useEffect(() => {
    getMoviesBySliderId(1, 0, 5)
      .then(data => {
        setMoviesCatalogo(prevState => {
          data.movies.forEach(movie => prevState.movies.push(movie));
          return { ...prevState };
        });
      })
      .catch(err => {
        console.log(err);
      });
    getMoviesBySliderId(4, 0, 5)
      .then(data => {
        setMoviesCatalogo(prevState => {
          data.movies.forEach(movie => prevState.movies.push(movie));
          return { ...prevState };
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, [getSliderDataArr]);

  useEffect(() => {
    getMoviesBySliderId(13, 10, 10)
      .then(data => {
        setMoviesClasicos({ movies: data.movies });
      })
      .catch(err => {
        console.log(err);
      });
    //Cambiar por ID slider Festival
    // getMoviesBySliderId(180, 0, 10)
    //   .then(data => {
    //     setMoviesFestival({ movies: data.movies });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }, []);

  return (
    <div className="home-no-user">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Xiclos</title>
        <meta name="description" content="Xiclos - Saber mirar" />
      </Helmet>
      <LoaderApp open={false} />
      <section className="no-user-header container-fluid px-0">
        <div className="row px-0 pl-md-5">
          <div className="col-12 col-lg-6 col-xl-6">
            <div className="xiclos-logo-container pt-5">
              <img
                alt="logo"
                id="logo-no-user"
                className="xiclos-logo"
                src="../images/logo.png"
              />
            </div>
            <div className="xiclos-title">
              <h1 className="text-uppercase">EL OTRO CINE</h1>
            </div>
            <p className="xiclos-description">
              Películas seleccionadas, de reposición permanente reseñadas por
              expertos, en excelente calidad multipantalla. Carga ágil,
              navegación sencilla y sin cortes.
            </p>
            <p className="xiclos-price">Comenzá a disfrutar del mejor cine</p>
            {/* <p className="xiclos-promotion">
              Y contás con un{" "}
              <span className="text-red">período de prueba de 30 días!</span>
            </p> */}
            <div className="xiclos-btn-suscribe pt-3">
              <button
                onClick={() => {
                  localStorage.setItem("is", "checked");
                  window.location.href = "sign-up.html";
                }}
                className="btn btn-hover btn-promotion">
                INICIA TUS 30 DIAS DE PRUEBA AHORA
              </button>
            </div>
            <div className="pt-3 d-none d-md-flex d-lg-none xiclos-mobile-login">
              <Link
                to="/login"
                onClick={() => localStorage.setItem("is", "checked")}
                className="btn btn-hover xiclos-btn xiclos-btn-inverter">
                INGRESAR
              </Link>
            </div>
          </div>
          <div className="col-1 col-md-6 col-lg-6 col-xl-6 flex-column justify-content-start align-items-end px-0 xiclos-free d-none d-sm-none d-lg-flex pr-md-5 pt-md-2">
            <button
              onClick={() => {
                localStorage.setItem("is", "checked");
                window.location.href = "sign-up.html";
              }}
              className="btn btn-hover mt-3 mx-3 xiclos-btn">
              REGISTRATE AQUI
            </button>

            <Link
              to="/login"
              onClick={() => localStorage.setItem("is", "checked")}
              className="btn btn-hover mt-3 mx-3 xiclos-btn xiclos-btn-inverter">
              INGRESAR
            </Link>
          </div>
        </div>
        <div className="row px-0 arrow-catalogo">
          <div className="col-12 text-center xiclos-catalogo">
            <div className="d-flex justify-content-center flex-column">
              {/* <p className="arrow-title">Visita el festival ahora!</p> */}
              <HashLink className="d-block" to="/#vortex-marcaron">
                <i className="icon-arrow-down"></i>
              </HashLink>
            </div>
          </div>
        </div>
      </section>
      {/** Festival Content **/}
      {/* <section className="show-movie-detail-section-tras" id="festival">
        <div className="container-fluid wrapper-movie-interest py-5 catalog-background px-2">
          <div className="iq-main-header d-flex align-items-center justify-content-center m-0">
            <h4 className="main-title m-0 text-center">Festival Arfecine</h4>
          </div>
          <p className="p-peliculas-marcaron mx-auto px-md-5 text-left text-md-center text-center px-4"></p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10 offset-0 offset-md-1 d-flex flex-column align-items-center justify-content-center btn-ver-catalogo">
                <button
                  id="ver-catalogo-completo"
                  className="btn btn-hover btn-subscribe"
                  onClick={() => {
                    localStorage.setItem("is", "checked");
                    history.push("/festival");
                  }}>
                  INGRESAR AL FESTIVAL
                </button>
              </div>
            </div>
          </div>
          <div className="row d-block py-1">
            <div
              id="vortex-catalogo"
              className="col-12 d-flex flex-wrap vortex-slides vortex-slides-pull mx-auto">
              {moviesFestival.movies.length !== 0 ? (
                <SliderPull
                  id={"vortex-recommend"}
                  slider={moviesFestival}
                  handleModal={() => {}}
                  freeMode
                  noLista
                  customClass="col-half-offset col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                />
              ) : (
                <div className="divLoading">
                  <Loader show />
                </div>
              )}
            </div>
          </div>
        </div>
      </section> */}
      {/** MainContent **/}
      <section className="show-movie-detail-section-tras" id="vortex-marcaron">
        <div className="container-fluid wrapper-movie-interest py-5 catalog-background px-2">
          <div className="iq-main-header d-flex align-items-center justify-content-center m-0">
            <h4 className="main-title m-0 text-center">
              <img src={CyanXiclos} alt="" className="margin-icons" />
              UN CATÁLOGO MARCADO POR EL MEJOR CINE
            </h4>
          </div>
          <p className="p-peliculas-marcaron mx-auto px-md-5 text-left text-md-center px-4 text-center">
            Películas de todo el mundo, de ahora y de siempre, para los amantes
            del buen cine.
          </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-10 offset-0 offset-md-1 d-flex flex-column align-items-center justify-content-center btn-ver-catalogo">
                <button
                  id="ver-catalogo-completo"
                  className="btn btn-hover btn-subscribe"
                  onClick={() => {
                    localStorage.setItem("is", "checked");
                    history.push("/home");
                  }}>
                  VER CATÁLOGO COMPLETO
                </button>
              </div>
            </div>
          </div>
          <div className="row d-block py-1">
            <div
              id="vortex-catalogo"
              className="col-12 d-flex flex-wrap vortex-slides vortex-slides-pull mx-auto">
              {moviesCatalogo.movies.length !== 0 ? (
                <SliderPull
                  id={"vortex-recommend"}
                  slider={moviesCatalogo}
                  handleModal={() => {}}
                  noLista
                  customClass="col-half-offset col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                />
              ) : (
                <div className="divLoading">
                  <Loader show />
                </div>
              )}
            </div>
          </div>
          <div className="row text-center">
            <p className="p-catalogo mx-auto">
              Es sólo una pequeñísima parte de nuestro catálogo. Mucho más cine
              te espera en Xiclos.
            </p>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="xiclos-btn-suscribe col-12 d-flex flex-column align-items-center justify-content-center">
                <button
                  onClick={() => {
                    localStorage.setItem("is", "checked");
                    window.location.href = "sign-up.html";
                  }}
                  className="btn btn-hover btn-promotion">
                  INICIA TUS 30 DIAS DE PRUEBA AHORA
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/**CineClub Directors */}
      <section className="cineclub-directors">
        <div className="container-fluid">
          <div className="block-space">
            <div className="row px-2">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F20.jpg?alt=media&token=18fa234c-b38a-4446-9b62-661c0b1e6dfe"
                    alt="Igmar Bergman"
                    title="Igmar Bergman"
                  />
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F26.jpg?alt=media&token=bcc0e89e-a303-42cf-91e0-173cd14b5323"
                    alt="Vittorio de Sica"
                    title="Vittorio de Sica"
                  />
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F34.jpg?alt=media&token=c748c57c-09d9-4e6c-8e99-71a888d9f280"
                    alt="Kim Ki-Duck"
                  />
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F30.jpeg?alt=media&token=d1fb208a-d3a4-491e-9445-9fded3857041"
                    alt="Alfred Hitchcock"
                    title="Alfred Hitchcock"
                  />
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F31.png?alt=media&token=4683162e-df21-4c1d-a405-4084a1f61e8f"
                    alt="Andrei Tarkovsky"
                    title="Andrei Tarkovsky"
                  />
                  <img
                    className="director-img"
                    src="https://firebasestorage.googleapis.com/v0/b/xiclos-dev.appspot.com/o/sliders%2F15.png?alt=media&token=0976a211-a086-4f68-9160-fc6f1c7f36dd"
                    alt="Pedro Almodóvar"
                    title="Pedro Almodóvar"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-start justify-content-center flex-column">
                <div className="cineclub-directors-description">
                  <div className="wrapper-cineclub">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h4 className="cineclub-main-title m-0">
                        <img
                          src={YellowXiclos}
                          alt=""
                          className="margin-icons"
                        />
                        CINECLUB EXCLUSIVO
                      </h4>
                    </div>
                  </div>
                  <p className="show-movie-tras-p" style={{ color: "#ffffff" }}>
                    Retrospectivas de directores y actores. <br />
                    Películas agrupadas en la selección exclusiva del Cineclub.{" "}
                    <br />
                    Xiclos de cine con los mejores del séptimo arte.
                  </p>
                  <div
                    className="d-flex align-items-center r-mb-23"
                    data-animation-in="fadeInUp"
                    data-delay-in="1">
                    <button
                      onClick={() => {
                        localStorage.setItem("is", "checked");
                        window.location.href = "sign-up.html";
                      }}
                      className="btn btn-hover btn-subscribe d-flex align-items-center justify-content-center">
                      SUSCRIBIRME AHORA
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** Peliculas que marcaron el cine */}
      <section id="section-catalogo" className="show-movie-detail-section-tras">
        <div className="container-fluid wrapper-movie-interest px-0 py-5 catalog-background">
          <div className="iq-main-header d-flex flex-column align-items-center justify-content-center mb-0">
            <h4 className="main-title m-0 text-center">
              <img src={CyanXiclos} alt="" className="margin-icons" />
              PELICULAS QUE MARCARON EL CINE
            </h4>
            <p className="p-peliculas-marcaron mx-auto px-md-5 text-left text-md-center text-center px-4">
              En Xiclos vas a encontrar siempre calidad: Películas premiadas,
              directores prestigiosos y de vanguardia, excelentes actores,
              nuevas miradas y diferentes propuestas.
            </p>
          </div>

          <div className="row d-block">
            <div
              id="vortex-clasicos"
              className="col-12 d-flex flex-wrap vortex-slides vortex-slides-pull px-md-5 mx-auto">
              {moviesClasicos.movies.length !== 0 ? (
                <SliderPull
                  id={"vortex-recommend"}
                  slider={moviesClasicos}
                  handleModal={() => {}}
                  noLista
                  customClass="col-half-offset col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                />
              ) : (
                <div className="divLoading">
                  <Loader show />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/** Peliculas que marcaron el cine */}
      <section id="iq-devices">
        <div className="container-fluid wrapper-devices">
          <div className="row">
            <div className="col-10 offset-1 col-md-5 py-4 text-right offset-1 offset-md-2">
              <img src="../images/xiclos-devices-1.png" alt="" />
            </div>
            <div className="col-10 offset-1 offset-md-0 col-md-5 d-flex flex-column align-items-start justify-content-center">
              <div className="xiclos-devices">
                <h4>
                  Disfrutá del otro <br />
                  cine, donde quieras
                </h4>
                <p className="m-2">
                  Viví Xiclos en todos tus dispositivos y formatos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="xiclos-ver-catalogo">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 offset-0 offset-md-1 d-flex flex-column align-items-center justify-content-center">
              <h4 className="text-center">
                Contamos con un amplio catálogo organizado por temas, ciclos,
                nacionalidades, géneros y directores para que tu búsqueda sea
                más ágil
              </h4>
              <button
                id="ver-catalogo-completo"
                className="btn btn-hover btn-subscribe"
                onClick={() => {
                  localStorage.setItem("is", "checked");
                  history.push("/home");
                }}>
                VER CATÁLOGO COMPLETO
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomeNoUser;
