import { API, HEADERS } from "./APIConnector";
import Firebase from "../config/Firebase";
// import {} from ".../";

export const getUserProfile = async () => {
  const user = JSON.parse(localStorage.getItem("U"));
  const userId = user.us_id;
  const request = {
    method: "GET",
    headers: HEADERS(),
  };
  let res = await fetch(API + "/user/" + userId, request);
  return res.json();
};

export const setUser = async (userObject, forceRefresh) => {
  if (
    (Object.keys(userObject).length === 0 && localStorage.getItem("U")) ||
    forceRefresh
  ) {
    let { data } = await getUserProfile();
    let nextPayment;
    if (
      data.us_billing.bi_payment_status === "pending" &&
      (data.us_billing.bi_payment_due === null ||
        data.us_billing.bi_payment_due === "")
    ) {
      nextPayment = `${data.us_billing.bi_payment_date.slice(
        8,
        10,
      )}/${data.us_billing.bi_payment_date.slice(
        5,
        7,
      )}/${data.us_billing.bi_payment_date.slice(0, 4)}`;
    } else {
      if (data.us_billing.bi_payment_due) {
        nextPayment = `${data.us_billing.bi_payment_due.slice(
          8,
          10,
        )}/${data.us_billing.bi_payment_due.slice(
          5,
          7,
        )}/${data.us_billing.bi_payment_due.slice(0, 4)}`;
      } else {
        nextPayment = "";
      }
    }
    let amount = "";
    let status = "";
    let membership = "";

    if (data.us_billing.bi_method === "Paypal") {
      if (data.subscriptions.length > 1) {
        amount = `USD ${
          data.subscriptions.filter(el => el.su_name !== "GENERAL")[0]
            .su_price_usd
        }`;
        membership = `Suscripción Xiclos ${
          data.subscriptions.filter(el => el.su_name !== "GENERAL")[0]
            .su_description
        }`;
      } else {
        amount = `USD ${data.subscriptions[0].su_price_usd}`;
        membership = `Suscripción Xiclos ${data.subscriptions[0].su_description}`;
      }
    } else {
      if (data.subscriptions.length > 1) {
        amount = `$${
          data.subscriptions.filter(el => el.su_name !== "GENERAL")[0]
            .su_price_ars
        }`;
        membership = `Suscripción Xiclos ${
          data.subscriptions.filter(el => el.su_name !== "GENERAL")[0]
            .su_description
        }`;
      } else {
        amount = `$${data.subscriptions[0].su_price_ars}`;
        membership += `Suscripción Xiclos ${data.subscriptions[0].su_description}`;
      }
    }

    switch (data.us_suscription_status) {
      case "active":
        status = "Activa";
        break;

      case "inactive":
        status = "Inactiva";
        break;

      case "pending":
        status = "Pendiente";
        break;

      case "free":
        status = "Free";
        break;

      default:
        status = "-";
        break;
    }

    return {
      name: data.us_name,
      lastname: data.us_lastname,
      country: data.us_billing.bi_country,
      state: data.us_billing.bi_state,
      city: data.us_billing.bi_city,
      zipcode: data.us_billing.bi_zip_code,
      email: data.us_email,
      nextPayment,
      method: data.us_billing.bi_method,
      amount,
      status,
      membership,
      subscription: data.us_billing.bi_subscription_id,
    };
  } else {
    return userObject;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    let r = await Firebase.updatePassword(oldPassword, newPassword);
    return r;
  } catch (err) {
    console.log(err);
  }
};

export const cancelSubscription = async () => {
  try {
    const request = {
      method: "POST",
      headers: HEADERS(),
    };
    let res = await fetch(API + "/user/cancelSubscription", request);
    let data = await res.json();
    if (data.success) {
      return null;
    } else {
      return data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const activeSubscription = async () => {
  try {
    const request = {
      method: "POST",
      headers: HEADERS(),
    };
    let res = await fetch(API + "/user/activeSubscription", request);
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const validateCreditCard = cardValues => {
  let errors = null;
  let { cvc, doc, expiry, name, number, issuerId, paymentMethod } = cardValues;

  if (
    cvc.length === "" ||
    doc.length === "" ||
    expiry.length === "" ||
    name.length === "" ||
    number.length === ""
  ) {
    errors = { ...errors, all: "*Debe completar todos los campos" };
  }

  if (
    number.length < 15 ||
    number.length > 19 ||
    isNaN(number) ||
    !issuerId ||
    !paymentMethod
  ) {
    errors = { ...errors, number: "*Los números de la tarjeta no son válidos" };
  }

  if (
    expiry.length !== 4 ||
    isNaN(expiry) ||
    parseInt(expiry.substring(0, 2)) < 1 ||
    parseInt(expiry.substring(0, 2)) > 12 ||
    parseInt(expiry.substring(2, 4)) < 21
  ) {
    errors = {
      ...errors,
      expiry:
        "*La fecha de vencimiento debe ser MMAA (mes y año), cómo lo indica la tarjeta",
    };
  }

  if (cvc.length > 4 || cvc.length < 3 || isNaN(cvc)) {
    errors = { ...errors, cvc: "*El código CVC es incorrecto" };
  }

  let nameCardNum = false;
  name.split("").forEach(char => {
    if (Number.isInteger(parseInt(char))) {
      nameCardNum = true;
    }
  });

  if (nameCardNum) {
    errors = {
      ...errors,
      name: "*El nombre del titular no debe contener números",
    };
  }

  return errors;
};
