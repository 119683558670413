import ImgDirector1 from "../../images/director/01.jpg"
import ImgDirector2 from "../../images/director/02.jpg"
import ImgDirector3 from "../../images/director/03.jpg"
import ImgDirector4 from "../../images/director/04.jpg"
import ImgDirector5 from "../../images/director/05.jpg"
import ImgDirector6 from "../../images/director/06.png"

export const DirectorMock = { di_id: 1,
  di_name: "Director Mock 1",
  di_lastname: "Director Prueba 1",
  di_img_link: "../../images/director/01.jpg"
}

export const DirectorListMock = {
  data: [
    { di_id: 1,
      di_name: "Director Mock 1",
      di_lastname: "Director Prueba 1",
      di_img_link: "../../images/director/01.jpg"
    },
    { di_id: 2,
      di_name: "Director Mock 2",
      di_lastname: "Director Prueba 2",
      di_img_link: ImgDirector2
    },
    { di_id: 3,
      di_name: "Director Mock 3",
      di_lastname: "Director Prueba 3",
      di_img_link: ImgDirector3
    },
    { di_id: 4,
      di_name: "Director Mock 4",
      di_lastname: "Director Prueba 4",
      di_img_link: ImgDirector4
    },
    { di_id: 5,
      di_name: "Director Mock 5",
      di_lastname: "Director Prueba 5",
      di_img_link: ImgDirector5
    },
    { di_id: 6,
      di_name: "Director Mock 6",
      di_lastname: "Director Prueba 6",
      di_img_link: ImgDirector6
    }
  ]
}