import { API } from "./APIConnector";
import Firebase from "../config/Firebase";

export const getAllMovies = async () => {
  const response = await fetch(API + "/all-movies", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  const data = await response.json();
  return data;
};

export const deleteMovie = async body => {
  const data = await fetch(`${API}/delete/movie`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      mo_id: body,
    }),
  });
  return await data.json();
};

export const deleteMovieImage = async (id, imageName) => {
  const storageRef = await Firebase.getStorageRef();

  const fileRef = await storageRef.child(`/movies/${id}/${imageName}`);

  await fileRef.delete();
};

export const updateMovieMetadata = async (id, body) => {
  const response = await fetch(`${API}/movie/seo/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
