import React from "react";
import ModalMui from "./ModalMui";
import { Box, Typography, Button } from "@mui/material";

const ModalConfirmation = ({
  open,
  onClose,
  message,
  onConfirmation,
  onCancel,
}) => {
  return (
    <ModalMui open={open} handleClose={onClose} maxWidth="xs">
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 20,
        }}>
        <Typography
          variant="h6"
          style={{ color: "black", textAlign: "center" }}>
          {message}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 20,
        }}>
        <Button
          style={{ marginRight: "5px" }}
          color="primary"
          disableElevation
          onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={onConfirmation}>
          Aceptar
        </Button>
      </Box>
    </ModalMui>
  );
};

export default ModalConfirmation;
