import React, { memo } from "react";
import Poster from "./Poster";
import SwiperCore, { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import useWidth from "../hooks/useWidth";
import "../css/slider-carousel.scss";
import { isEmpty } from "lodash";

SwiperCore.use([Navigation, Pagination, Virtual]);

// const navigation = isSmallDevice?false:{nextEl:'<a href="#" class="slick-arrow slick-next"><i class="ri-arrow-right-s-fill"></i></a>',
//                                         prevEl:'<a href="#" class="slick-arrow slick-prev"><i class="ri-arrow-left-s-fill"></i></a>'}

//TODO: Render slider se renderiza muchas mas veces de las necesarias
const renderSlider = (movie, idx, slId, handleModal, freeMode) => {
  if (isEmpty(movie)) {
    return null;
  }
  return (
    <SwiperSlide key={"sm-" + slId + "-" + idx} virtualIndex={idx}>
      <Poster
        item={movie}
        index={idx}
        handleModal={handleModal}
        freeMode={freeMode}
      />
    </SwiperSlide>
  );
};

const SliderCarousel = props => {
  const {
    slider,
    onFetchMore,
    onSlideChange,
    handleModal,
    freeMode,
    ...propsToSwiper
  } = props;
  const width = useWidth();
  // console.log("CARROUSEL MOVIES", slider.sl_id, slider.movies)
  // const emptyMovies = new Array(countMovies-slider.movies.length)
  // emptyMovies.fill({})
  // const initialMovies = slider.movies.concat(emptyMovies)
  // const [movies, setMovies] = useState(slider.movies)
  // console.log("SLIDERS", slider.sl_id, slider.movies.length,countMovies, movies.length)
  const swiperProps = {
    // freeMode: width === "xs" || width === "sm" ? false : true,
    // loop:false,
    virtual: true,
    loopFillGroupWithBlank: true,
    navigation: width === "xs" || width === "sm" ? false : true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 2,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 2,
      },
      // when window width is >= 640px
      // 768: {
      //   slidesPerView: 6,
      //   slidesPerGroup: 6,
      //   spaceBetween: 2,
      // },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 2,
      },
      1200: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 2,
      },
      1400: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 2,
      },
      1800: {
        slidesPerView: 8,
        slidesPerGroup: 8,
        spaceBetween: 2,
      },
    },
  };

  return (
    <Swiper
      className="slider-carousel"
      key={"ss" + slider.sl_id}
      {...swiperProps}
      {...propsToSwiper}
      //loop={(width === "xs" || width === "sm") && slider.count_movies > 3}
      // style={{paddingLeft:40, paddingRight:40}}
      slidesPerView={3}
      onSlideChange={onSlideChange}>
      {slider &&
        slider.movies.map((el, idx) =>
          renderSlider(el, idx, slider.sl_id, handleModal, freeMode),
        )}
    </Swiper>
  );
};

export default memo(SliderCarousel);
