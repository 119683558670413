import React from "react";
import ModalMui from "./ModalMui";
import { Box, Typography, CircularProgress } from "@mui/material";

const LoadingMsgModal = ({
  open,
  handleClose = () => {},
  maxWidth = "xs",
  message = "",
  loadingMessage = "Cargando...",
}) => {
  return (
    <ModalMui open={open} handleClose={handleClose} maxWidth={maxWidth}>
      {message && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            marginBottom: 20,
          }}>
          <Typography variant="h6" style={{ color: "black" }}>
            {message}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 20,
        }}>
        <Typography variant="h5" style={{ color: "black" }}>
          {loadingMessage}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 20,
        }}>
        <CircularProgress />
      </Box>
    </ModalMui>
  );
};

export default LoadingMsgModal;
