import React, { memo } from "react";

import "../css/banner.scss";
import useWidth from "../hooks/useWidth";

const Banner = props => {
  const { banner } = props;
  const width = useWidth();
  const textFormatted = banner.bn_description
    .split("<br />")
    .map((text, index) => {
      return (
        <p
          className="p-home-banners"
          data-animation-in="fadeInUp"
          data-delay-in="0.7"
          key={index}>
          {text}
          <br />
        </p>
      );
    });

  const bannerTitleTextStyle = () => {
    return width === "xs" || width === "sm"
      ? { fontSize: "6vh" }
      : width === "md"
      ? { fontSize: "7vh" }
      : width === "lg"
      ? { fontSize: "8vh", paddingTop: "10%" }
      : { fontSize: "8.8vh", paddingTop: "10%" };
  };
  return (
    <div
      className={`banner-component ${
        width === "xs" || width === "sm"
          ? "slider-shadow"
          : "slide slider-shadow h-100"
      }`}
      style={{
        backgroundImage: `url(${banner.bn_image})`,
        height: width === "xs" || width === "sm" ? "400px" : "100%",
      }}>
      <div className="container-fluid h-100">
        <div className="slider-inner h-100">
          <div className="row align-items-center  h-100">
            <div className="col-xl-6 col-lg-6 col-md-12 text-container">
              <h1
                className={
                  width === "xs" || width === "sm"
                    ? "slider-text text-uppercase"
                    : "slider-text title text-uppercase home-slider-movie-title title-home-banners"
                }
                style={bannerTitleTextStyle()}
                data-animation-in="fadeInLeft">
                {banner.bn_name}
              </h1>
              {width === "xs" || width === "sm" ? null : (
                <div>{textFormatted}</div>
              )}
              <div
                className="d-flex align-items-center r-mb-23"
                data-animation-in="fadeInUp"
                data-delay-in="1">
                <a href={banner.bn_link} className="btn btn-hover">
                  {banner.bn_button_name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Banner);
