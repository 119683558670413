import React from "react";

import CloseIcon from "@mui/icons-material/Close";

import { makeStyles, useTheme } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

const useStyle = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-paper": {
      margin: 0,
      maxHeight: "calc(100% - 25px)",
      width: "calc(100% - 25px)",
    },
  },
}));

const ModalMui = ({
  children,
  open,
  handleClose,
  actions,
  title,
  close,
  maxWidth,
  fullWidth,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  return (
    <Dialog
      disableEnforceFocus
      disableScrollLock
      onClose={handleClose}
      open={open}
      className={classes.dialog}
      fullWidth={fullWidth}
      maxWidth={maxWidth}>
      {title ? (
        <DialogTitle style={{ margin: 0, padding: 2, color: "black" }}>
          <Typography
            style={{
              color: "black",
              margin: 16,
              fontWeight: "500",
              fontSize: 25,
            }}>
            {title}
          </Typography>
          {close ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : close ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}

      <DialogContent dividers>{children}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};

export default ModalMui;
