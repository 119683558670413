import React from "react";
import { useHistory } from "react-router";
import { Typography, IconButton } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";

const NavigationTitle = ({ icon, title, descriptionText }) => {
  const history = useHistory();
  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage:
          "linear-gradient(to right, #1a1a1a 0%, #343433 49%, #1a1a1a 100%)",
      }}>
      <div className="block-space xiclo-title">
        <Typography variant="h4" style={{ fontFamily: "Abel" }}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}>
            <BackIcon
              style={{ color: "white", fontSize: "3rem", marginBottom: 4 }}
            />
          </IconButton>
          {icon && (
            <img
              src={icon}
              style={{
                marginLeft: 12,
                marginRight: 12,
                marginBottom: 2,
                height: 30,
              }}
              alt=""
            />
          )}
          {title}
        </Typography>
        {descriptionText && (
          <div className="row align-items-center">
            <div className="col-12">
              <div className="col first-section-text">{descriptionText}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationTitle;
