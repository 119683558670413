import { API, HEADERS } from "./APIConnector";

export const getMovie = async id => {
  const resp = await fetch(API + "/movie/no-progress/" + id, HEADERS());
  return resp.json();
};

export const getSlider = async id => {
  const resp = await fetch(
    API + "/slider?id=" + id + "&limitMovies=6&offsetMovies=0",
    HEADERS(),
  );
  return resp.json();
};
