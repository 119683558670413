import React from "react";
import Banner from "./Banner";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import useWidth from "../hooks/useWidth";

SwiperCore.use([Autoplay, Navigation, Pagination, Virtual]);

const renderBanner = (banner, idx) => {
  return (
    <SwiperSlide key={"bm" + idx}>
      <Banner banner={banner} />
    </SwiperSlide>
  );
};

const BannerCarousel = props => {
  const { banners } = props;

  // console.log(banners.sort((a, b) => b.bn_order - a.bn_order));

  const width = useWidth();
  const swiperProps = {
    autoplay: {
      delay: 5500,
    },
    loop: true,
    navigation: width === "xs" || width === "sm" ? false : true,
    pagination: width === "xs" || width === "sm" ? false : true,
    slidesPerView: 1,
  };

  return (
    <Swiper
      className="banner-carousel"
      style={{ height: width === "xs" || width === "sm" ? "350px" : "100%" }}
      {...swiperProps}
      // onSlideChange={onSlideChange}
    >
      {banners && banners.map(renderBanner)}
    </Swiper>
  );
};

export default BannerCarousel;
