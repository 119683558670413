import React, { useEffect, useCallback, useState } from "react";

import "../css/watch-video.scss";

import { IconButton, Grid } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import { useHistory } from "react-router-dom";
import { useQuery } from "../hooks/useQuery";
import { API, CDN_JWPLAYER } from "../provider/APIConnector";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import Firebase from "../config/Firebase";
import { refreshTokenAction } from "../actions/UserActions";
import { Helmet } from "react-helmet";
import JWPlayer from "@jwplayer/jwplayer-react";

const Watch = ({ freeMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [player, setPlayer] = useState(null);
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [movie, setMovie] = useState(null);
  const [playerType, setPlayerType] = useState(null);

  const saveMovieProgressJWPlayer = useCallback(async () => {
    if (!player) return;
    const position = player.getPosition();
    const posFloor = Math.floor(position);
    if (posFloor < 30) return;
    try {
      await (
        await fetch(API + "/movie/progress?id=" + query.get("id"), {
          method: "PUT",
          body: JSON.stringify({ time: posFloor }),
          headers: {
            Authorization: localStorage.getItem("Authorization"),
            U: localStorage.getItem("U"),
            "Content-Type": "application/json",
          },
        })
      ).json();
      console.log("progress updated");
    } catch (err) {
      console.log(err);
    }
  }, [player, query]);

  const initializeMovie = useCallback(async () => {
    try {
      const responseMovie = await (
        await fetch(
          `${API}/movie/watch?id=${query.get("id")}${
            freeMode ? "&free=true" : ""
          }`,
          {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("Authorization"),
              U: localStorage.getItem("U"),
            },
          },
        )
      ).json();
      const id = responseMovie.data.mo_jw_media_id;
      const progressData = responseMovie?.data?.mo_progress[0]?.mp_progress;
      if (!isNaN(Number(id))) {
        const responseVimeoFiles = await (
          await fetch(`${API}/v2/vimeo/${id}`, {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("Authorization"),
              U: localStorage.getItem("U"),
            },
          })
        ).json();
        setMovie(prev => ({
          ...prev,
          files: responseVimeoFiles?.data,
        }));
        setPlayerType("vimeo");
      } else {
        setPlayerType("jwplayer");
      }
      setMovie(prev => ({
        ...prev,
        title: responseMovie,
        id: id,
        seek: Number(progressData),
      }));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setIsError(true);
    }
  }, [freeMode, query]);

  const refreshToken = () => setRefresh(prevState => !prevState);

  useEffect(() => {
    initializeMovie();
    return () => setPlayer(null);
  }, [initializeMovie]);

  useEffect(() => {
    const interval = setInterval(async () => {
      saveMovieProgressJWPlayer();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [playerType, saveMovieProgressJWPlayer]);

  useEffect(() => {
    let timer = setTimeout(() => {
      Firebase.onAuthStateChanged(userProvided => {
        if (userProvided) {
          dispatch(refreshTokenAction(userProvided, refreshToken));
        }
      });
    }, 1800000);
    return () => {
      clearTimeout(timer);
    };
  }, [refresh, dispatch]);

  const onBeforePlayJWPlayer = () => {};

  const playerMountedCallbackJWPlayer = ({ player, id }) => {
    setPlayer(player);
    player.seek(movie.seek);
    player.setCurrentCaptions(1);
    reloadOnCastJWPlayer(player);
  };

  const reloadOnCastJWPlayer = player => {
    player.on("cast", cast => {
      if (cast && cast?.active) {
        const tracks = player.getCaptionsList();
        if (tracks && tracks?.length > 0) {
          player.setCurrentCaptions(1);
        }
      }
    });
  };

  const playerUnmountingCallbackJWPlayer = () => {
    setPlayer(null);
  };

  if (isError) {
    return (
      <div id="video-container">
        <div className="divLoading" style={{ height: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <h3>
                Ocurrió un error al cargar la película, o no se encuentra
                disponible
              </h3>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "40px" }}>
              <IconButton
                size="medium"
                style={{ backgroundColor: "white" }}
                onClick={() => history.goBack()}>
                <ArrowBackRoundedIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  if (loading || playerType === null || movie === null) {
    return (
      <div id="video-container">
        <div className="divLoading" style={{ height: "100%" }}>
          <Loader show style={{ height: "100%", fontSize: "xxx-large" }} />
        </div>
      </div>
    );
  }

  return (
    <div className="watch-video" id="vortex-movie-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Watch - Xiclos"}</title>
        <meta
          name="description"
          content="Página de reproducción de una película"
        />
      </Helmet>
      <div id="watch-video-back">
        <IconButton
          size="small"
          style={{ backgroundColor: "white" }}
          onClick={() => history.goBack()}>
          <ArrowBackRoundedIcon fontSize="large" style={{ fontSize: 60 }} />
        </IconButton>
      </div>
      <div id="video-container">
        {movie && playerType === "jwplayer" && movie?.id && (
          <JWPlayer
            config={{
              responsive: true,
              stretching: "uniform",
              volume: 75,
              horizontalVolumeSlider: true,
              autostart: true,
              displayPlaybackLabel: true,
            }}
            willUnmountCallback={playerUnmountingCallbackJWPlayer}
            didMountCallback={playerMountedCallbackJWPlayer}
            onBeforePlay={onBeforePlayJWPlayer}
            playlist={`${CDN_JWPLAYER}/${movie.id}`}
            library="https://cdn.jwplayer.com/libraries/uAC0h9eo.js"
          />
        )}
        {movie && playerType === "vimeo" && movie?.files && movie?.id && (
          <JWPlayer
            config={{
              responsive: true,
              stretching: "uniform",
              volume: 75,
              horizontalVolumeSlider: true,
              autostart: true,
              displayPlaybackLabel: true,
            }}
            willUnmountCallback={playerUnmountingCallbackJWPlayer}
            didMountCallback={playerMountedCallbackJWPlayer}
            onBeforePlay={onBeforePlayJWPlayer}
            playlist={movie.files}
            library="https://cdn.jwplayer.com/libraries/uAC0h9eo.js"
          />
        )}
      </div>
    </div>
  );
};

export default Watch;
