import React, { memo } from "react";
import { useHistory } from "react-router-dom";

const styles = {
  img: {
    cursor: "pointer",
  },
};

const Director = props => {
  const { director } = props;
  const history = useHistory();
  return (
    <div className="wrapper-directors">
      <img
        src={director.di_img_link}
        className="img-fluid mx-auto"
        alt=""
        onClick={() => {
          history.push("/cine-club");
        }}
        style={styles.img}
      />
    </div>
  );
};

export default memo(Director);
