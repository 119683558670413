/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import Firebase from "../config/Firebase";

import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  MenuList,
  Button,
} from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import DrawerMenu from "./DrawerMenu";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Fade } from "@mui/material";
import useWidth from "../hooks/useWidth";
import SearchHeader from "./SearchHeader";
import { SUBSCRIPTIONS } from "../common/Constant";

const Header = () => {
  const width = useWidth();
  const history = useHistory();
  const usr = useSelector((state: any) => state.usr);
  const [anchorMenu, setAnchorMenu] = useState(false);
  const openMenu = Boolean(anchorMenu);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);

  useEffect(() => {
    if (usr && usr?.user && usr?.user?.subscriptions) {
      setIsExclusive(
        usr.user.subscriptions.some((sub: any) => {
          return (
            sub.su_name === SUBSCRIPTIONS.exclusivo ||
            sub.su_name === SUBSCRIPTIONS.exclusivoAntiguo ||
            sub.su_name === SUBSCRIPTIONS.video24
          );
        }),
      );
    }
  }, [usr?.user]);

  const onToggleUserMenu = (evt: any) => {
    if (anchorMenu) {
      setAnchorMenu(false);
    } else {
      setAnchorMenu(evt.currentTarget);
    }
  };

  const onLogout = () => {
    Firebase.signOut()
      .then(() => {
        localStorage.removeItem("U");
        localStorage.removeItem("Authorization");
        window.location.href = "/";
      })
      .catch(() => {
        console.log("algo salio mal");
      });
  };

  const onToggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <>
      <Fade in={true}>
        <header id="main-header">
          <div className="main-header">
            <div className="container-fluid px-3">
              <div className="row">
                <div className="col-sm-12">
                  <nav className="navbar navbar-expand-lg navbar-light p-0">
                    {width === "xs" && (
                      <div
                        style={{
                          display: "flex",
                          width: "44%",
                          justifyContent: "space-between",
                        }}>
                        <DrawerMenu />
                        <ul className="d-flex align-items-center list-inline m-0">
                          <li
                            className={`active iq-show nav-item nav-icon ${
                              !usr.user && "mr-5"
                            }`}>
                            <button
                              style={{ fontSize: "1.8rem", color: "#d1d0cf" }}
                              className="search-toggle device-search"
                              onClick={onToggleSearch}>
                              <i className="ri-search-line"></i>
                            </button>
                            <SearchHeader open={searchOpen} />
                          </li>
                        </ul>
                      </div>
                    )}
                    <Link className="navbar-brand" to="/home">
                      <img
                        className="img-fluid logo"
                        src="../images/logo.png"
                        alt="streamit"
                      />
                    </Link>
                    <div
                      className="collapse navbar-collapse collapsed"
                      id="navbarSupportedContent">
                      <div
                        className="menu-main-menu-container"
                        style={{
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}>
                        <ul id="top-menu" className="navbar-nav ml-auto">
                          {/* <li className="menu-item">
                            <Link to="/festival" className="device-search">
                              Festival
                            </Link>
                          </li> */}
                          <li className="menu-item">
                            <Link to="/explore" className="device-search">
                              Explorar
                            </Link>
                          </li>
                          <li className="menu-item">
                            <Link to="/cine-club" className="device-search">
                              Cineclub
                            </Link>
                          </li>
                          {usr.user && (
                            <li className="menu-item">
                              <Link to="/my-list" className="device-search">
                                Mi lista
                              </Link>
                            </li>
                          )}
                          {usr.user && (
                            <li className="menu-item">
                              <Link to="/sync-device" className="device-search">
                                Sincronizar dispositivo
                              </Link>
                            </li>
                          )}
                          {isExclusive && (
                            <li className="menu-item">
                              <i className="ri-star-fill" />
                              <Link to="/exclusive" className="device-search">
                                Exclusivo
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="navbar-right menu-right">
                      <ul className="d-flex align-items-center list-inline m-0">
                        <li
                          style={{ marginLeft: "9px" }}
                          className={`active iq-show nav-item nav-icon ${
                            !usr.user && "mr-5"
                          }`}>
                          <button
                            style={{ fontSize: "1.8rem", color: "#d1d0cf" }}
                            className="search-toggle device-search"
                            onClick={onToggleSearch}>
                            <i className="ri-search-line"></i>
                          </button>
                          <SearchHeader open={searchOpen} />
                        </li>
                        {usr.user ? (
                          <>
                            <li>
                              <Button
                                style={{ color: "#d1d0cf" }}
                                size="large"
                                className="help-link"
                                color="inherit"
                                variant="outlined"
                                startIcon={<HelpOutlineOutlinedIcon />}
                                onClick={() => history.push("/faqs")}>
                                {width === "md" ? "Ayuda" : "¿Necesitás ayuda?"}
                              </Button>
                            </li>
                            {/* <div>
                              <Button
                                style={{ color: "#d1d0cf" }}
                                size="large"
                                className="help-link"
                                color="inherit"
                                variant="outlined"
                                startIcon={<HelpOutlineOutlinedIcon />}
                                onClick={() => history.push("/watch-vimeo")}>
                                {width === "md" ? "Ayuda" : "VIMEO"}
                              </Button>
                            </div> */}
                            <IconButton onClick={onToggleUserMenu}>
                              <Avatar
                                src={
                                  usr.user?.us_avatar
                                    ? `${usr.user?.us_avatar}`
                                    : ""
                                }
                                style={{ background: "var(--xiclos-bg)" }}>
                                {usr.user.us_name[0]}
                              </Avatar>
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/login"
                                className="btn btn-hover xiclos-btn xiclos-btn-inverter">
                                Login
                              </Link>
                            </li>
                            <li>
                              <a
                                href="./sign-up.html"
                                className="btn btn-hover xiclos-btn xiclos-btn-inverter">
                                Suscribirse
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </nav>
                  <div className="nav-overlay"></div>
                </div>
              </div>
            </div>
          </div>

          {usr.user ? (
            <Menu
              open={openMenu}
              anchorEl={anchorMenu as any}
              onClose={() => {
                setAnchorMenu(false);
              }}
              // getcontentanchorel={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              disableScrollLock>
              <MenuList>
                <MenuItem onClick={() => history.push("/profile")}>
                  Mi cuenta
                </MenuItem>
                {(usr.isAdmin || usr.isSeo) && (
                  <MenuItem onClick={() => history.push("/panel-control")}>
                    Panel de control
                  </MenuItem>
                )}
                <MenuItem onClick={onLogout}>Cerrar Sesión</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            ""
          )}
        </header>
      </Fade>
      <hr style={{ margin: 0, padding: "75px 0 0 0" }} />
    </>
  );
};

export default Header;
