import React, { CSSProperties } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ASPECT_RATIO_POSTER } from "../../../common/Constant";

interface LoaderProps {
  show: boolean;
  style?: CSSProperties;
}

const Loader = ({ show, style }: LoaderProps) => {
  if (show) {
    return (
      <Box
        style={{
          width: "100%",
          maxWidth: 200,
          minHeight: 311,
          aspectRatio: ASPECT_RATIO_POSTER,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(26, 26, 26, 0.9)",
          borderRadius: 5,
          ...style,
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return null;
};

export default Loader;
