import React from "react";
import ModalMui from "./ModalMui";
import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modalInfoBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
});

const ModalInfo = ({
  open,
  handleClose = () => {},
  message,
  onConfirmation = () => {},
}) => {
  const classes = useStyles();
  return (
    <ModalMui open={open} handleClose={handleClose} maxWidth="xs">
      <Box className={classes.modalInfoBox}>
        <Typography variant="h6" style={{ color: "black" }}>
          {message}
        </Typography>
      </Box>
      <Box className={classes.modalInfoBox}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onConfirmation}>
          Aceptar
        </Button>
      </Box>
    </ModalMui>
  );
};

export default ModalInfo;
