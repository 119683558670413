import { ACT_CINECLUB_FETCH_MOVIES } from "../actions/ActionTypes";

const initialState = {
  sliders: [],
};

export default function (
  state = initialState,
  action: { type: any; payload: any },
) {
  switch (action.type) {
    case ACT_CINECLUB_FETCH_MOVIES:
      return {
        ...state,
        sliders: action.payload,
      };
    default:
      return state;
  }
}
