/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import useWidth from "../hooks/useWidth";
import { useHistory } from "react-router-dom";
import { Collapse, TextField, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import "../css/search-header.scss";

const useStyles = makeStyles({
  input: {
    color: "red",
    "& .MuiInputBase-root": {
      color: "white",
    },
  },
});

const SearchHeader = ({ open }: { open: boolean }) => {
  const width = useWidth();
  const history = useHistory();
  const searchInputRef = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    searchInputRef.current?.focus();
  }, [open]);

  const inputPropsDesktop = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon style={{ color: "#d1d0cf" }} />
      </InputAdornment>
    ),
  };

  const inputPropsMobile = {
    endAdornment: (
      <IconButton
        className="search-button-mobile"
        onClick={() => {
          history.push(`/result?search=${searchInputRef.current.value || ""}`);
          searchInputRef.current.value = "";
        }}>
        <SearchIcon className="search-icon" fontSize="large" />
      </IconButton>
    ),
  };

  return (
    <div className="search-header">
      <div
        className={`search-box iq-search-bar d-search ${classes.input} ${
          width === "xs" ? "search-bar-mobile" : "search-bar"
        }`}>
        <Collapse in={open}>
          <TextField
            size="medium"
            autoFocus
            variant="standard"
            inputRef={searchInputRef}
            type="text"
            placeholder="Buscar..."
            className={`text search-input font-size-12`}
            InputProps={width === "xs" ? inputPropsMobile : inputPropsDesktop}
            onKeyDown={e => {
              if (e.key === "Enter") {
                history.push(
                  `/result?search=${
                    (e.target as HTMLInputElement).value || ""
                  }`,
                );
                (e.target as HTMLInputElement).value = "";
              }
            }}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default SearchHeader;
