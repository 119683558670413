import React, { Fragment, useEffect, useState, useCallback } from "react";
import "../css/panel-control-users.scss";
import {
  Paper,
  IconButton,
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  Chip,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  OutlinedInput,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";
import { API } from "../provider/APIConnector";
import firebase from "firebase";
import ModalMui from "../components/ModalMui";
import EditUserContent from "../components/EditUserContent";
import ChangeStateUserContent from "../components/ChangeStateUserContent";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/styles";
import {
  updateSubscription,
  updateNotes,
  updatePassword,
  getUsers,
  deleteUser,
  changeState,
} from "../provider/UserService";
import { getRoles } from "../provider/RolService";
import { getSubscriptions } from "../provider/SubscriptionService";
import { subscriptionStateData, billingStateData } from "../common/Util";
import { Formik } from "formik";
import moment from "moment";
import MenuPanelControlUsers from "../components/MenuPanelControlUsers";
import { Helmet } from "react-helmet";
import axiosInit from "../provider/axios";

function PanelControlUsers() {
  const $ = window.$;
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingBackDrop, setLoadingBackDrop] = useState(false);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    message: "",
  });
  const [table, setTable] = useState(null);
  const [infoModal, setInfoModal] = useState({ open: false, message: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeSubModal, setChangeSubModal] = useState(false);
  const [changeRolModal, setChangeRolModal] = useState(false);
  const [changeStateModal, setChangeStateModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalAlterSub, setModalAlterSub] = useState(false);
  const [alterType, setAlterType] = useState(null);

  const createUsersTable = useCallback(() => {
    const table = $("#user-table")
      .DataTable({
        searchPanes: {
          cascadePanes: true,
          dtOpts: {
            dom: "tp",
            paging: "true",
            pagingType: "numbers",
            searching: true,
          },
        },

        language: {
          lengthMenu: "Mostrar _MENU_ registros",
          zeroRecords: "No se encontraron resultados",
          info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          infoEmpty: "Mostrando registros del 0 al 0 de un de 0 registros",
          infoFiltered: "(filtrado de un total de _MAX_ registros)",
          sSearch: "Buscar:",
          sLoadingRecords: "Cargando...",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },

          select: {
            rows: {
              _: "%d usuarios seleccionados",
              0: "Haga click en el campo seleccionable para seleccionar el usuario",
              1: "1 usuario seleccionado",
            },
          },

          searchPanes: {
            emptyPanes: "No hay filtros para mostrar",
            clearMessage: "Limpiar filtros",
            title: {
              _: " %d filtros seleccionados",
              0: "Ningún filtro seleccionado",
              1: "1 filtro seleccionado",
            },
          },
        },

        responsive: true,
        dom: "BfPrtip",
        buttons: [
          {
            extend: "excelHtml5",
            text: '<i class="ri-file-excel-2-fill"></i>',
            titleAttr: "Exportar a Excel",
            className: "btn btn-success m-btn-export",
            exportOptions: { columns: [3, 4, 5, 6, 7, 8, 9, 10] },
          },
          {
            extend: "csvHtml5",
            text: '<i class="ri-file-excel-fill"></i>',
            titleAttr: "Exportar a CSV",
            className: "btn btn-info m-btn-export",
            exportOptions: { columns: [3, 4, 5, 6, 7, 8, 9, 10] },
          },
          {
            extend: "pdfHtml5",
            orientation: "landscape",
            text: '<i class="fas fa-file-pdf"></i>',
            titleAttr: "Exportar a PDF",
            className: "btn btn-danger m-btn-export",
            exportOptions: { columns: [3, 4, 5, 6, 7, 8, 9, 10] },
          },
        ],
        order: [[9, "desc"]],
        columnDefs: [
          {
            className: "select-checkbox",
            orderable: false,
            targets: 1,
          },
          {
            searchPanes: {
              show: false,
            },
            targets: [8],
          },
          {
            className: "control",
            orderable: false,
            targets: 0,
          },
        ],

        select: {
          items: "row",
          style: "multi",
          selector: "td:nth-child(2)",
        },
      })
      .searchPanes.rebuildPane();

    setTable(table);
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: false,
        message: "",
      };
    });
  }, [$]);

  const initializePanelControlUsers = useCallback(async () => {
    try {
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: true,
          message: "Obteniendo información de usuarios...",
        };
      });
      const responseSubscriptions = await getSubscriptions();

      setSubscriptions(responseSubscriptions.data);

      const responseRoles = await getRoles();
      setRoles(responseRoles.data);
      updateUsersTable(createUsersTable);
    } catch (err) {
      console.log(err);
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: false,
          message: "",
        };
      });
      setInfoModal(prevState => {
        return {
          ...prevState,
          open: true,
          message: "Ocurrió un error",
        };
      });
    }
  }, [createUsersTable]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      initializePanelControlUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [initializePanelControlUsers]);

  const handleCloseModalEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseModalChangeStateUser = () => {
    setChangeStateModal(false);
  };

  const updateUsersTable = callBack => {
    getUsers()
      .then(data => {
        if (data.code === 200) {
          let temp = {};
          data.data.forEach(user => {
            temp = { ...temp, [user.us_id]: null };
          });
          setAnchorEl(temp);
          setUsers(data.data);
          callBack();
        } else {
          setLoadingModal(prevState => {
            return {
              ...prevState,
              open: false,
              message: "",
            };
          });
          setInfoModal(prevState => {
            return {
              ...prevState,
              open: true,
              message: "Ocurrió un error",
            };
          });
        }
      })
      .catch(err => {
        console.log(err);
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
        setInfoModal(prevState => {
          return {
            ...prevState,
            open: true,
            message: "Ocurrió un error",
          };
        });
      });
  };

  const handleChangeSubscription = (subValues, userId) => {
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "",
      };
    });
    fetch(API + "/update/user/subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sb_us_id: userId,
        sb_su_id: subValues,
      }),
    })
      .then(res => res.json())
      .then(data => {
        setChangeSubModal(false);
        if (data.code === 200) {
          updateUsersTable(() => {
            table.destroy();
            createUsersTable();
            setLoadingModal(prevState => {
              return {
                ...prevState,
                open: false,
                message: "",
              };
            });
            successCallback("Suscripción modificada con éxito");
          });
        } else {
          errorCallback();
          setLoadingModal(prevState => {
            return {
              ...prevState,
              open: false,
              message: "",
            };
          });
        }
      })
      .catch(err => {
        console.log(err);
        errorCallback();
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
      });
  };

  const handleAlterSubscription = async () => {
    try {
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: true,
          message: "",
        };
      });
      await axiosInit.post(`/v2/admin/alter-subscription`, {
        userId: selectedUser.us_id,
        alter: alterType,
      });
      updateUsersTable(() => {
        table.destroy();
        createUsersTable();
        setModalAlterSub(false);
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
        successCallback("Suscripción modificada con éxito");
      });
    } catch (err) {
      console.log(err);
      setModalAlterSub(false);
      errorCallback(
        err?.response?.data?.errorMessage
          ? err.response.data.errorMessage
          : "Ocurrió un error al modificar la suscripción",
      );
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: false,
          message: "",
        };
      });
    }
  };

  const handleChangeRoles = (rolIDs, userId) => {
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "",
      };
    });
    fetch(API + "/update/roles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ur_us_id: userId,
        ur_rol_id: rolIDs,
      }),
    })
      .then(res => res.json())
      .then(data => {
        setChangeRolModal(false);
        if (data.code === 200) {
          updateUsersTable(() => {
            table.destroy();
            createUsersTable();
            setLoadingModal(prevState => {
              return {
                ...prevState,
                open: false,
                message: "",
              };
            });
            successCallback("Rol/es modificado con éxito");
          });
        } else {
          errorCallback();
          setLoadingModal(prevState => {
            return {
              ...prevState,
              open: false,
              message: "",
            };
          });
        }
      })
      .catch(err => {
        console.log(err);
        errorCallback();
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
      });
  };

  const handleEditUser = async (user, values, setSubmitting) => {
    try {
      setSubmitting(true);
      setLoadingBackDrop(true);
      const dataSub = await updateSubscription({
        sb_us_id: user.us_id,
        sb_su_id: values.subscriptionValue,
      });
      const dataNote = await updateNotes({
        us_id: user.us_id,
        us_notes: values.notas,
      });
      if (values.password !== "") {
        const dataPassword = await updatePassword({
          user,
          password: values.password,
        });
        if (
          dataSub.code === 200 &&
          dataNote.code === 200 &&
          dataPassword.code === 200
        ) {
          updateUsersTable(() => {
            table.destroy();
            createUsersTable();
            setSubmitting(false);
            setLoadingBackDrop(false);
            successCallback();
          });
        } else {
          setSubmitting(false);
          errorCallback();
        }
      } else {
        if (dataSub.code === 200 && dataNote.code === 200) {
          updateUsersTable(() => {
            table.destroy();
            createUsersTable();
            setSubmitting(false);
            setLoadingBackDrop(false);
            successCallback();
          });
        } else {
          setSubmitting(false);
          setLoadingBackDrop(false);
          errorCallback();
        }
      }
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      setLoadingBackDrop(false);
      errorCallback();
    }
  };

  const recoverPasswordEmail = email => {
    firebase.auth().languageCode = "es";
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        successResetEmail();
      })
      .catch(error => {
        errorCallback();
      });
  };

  const handleDeleteUser = () => {
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "",
      };
    });
    setConfirmationModal(prevState => {
      return {
        ...prevState,
        open: false,
        message: "",
      };
    });
    deleteUser(selectedUser.us_id)
      .then(data => {
        if (data.code === 200) {
          setDeleteModal(true);
        } else {
          if (data.errorMessage) {
            errorCallback(data.errorMessage);
            setLoadingModal(prevState => {
              return {
                ...prevState,
                open: false,
                message: "",
              };
            });
          } else {
            errorCallback();
            setLoadingModal(prevState => {
              return {
                ...prevState,
                open: false,
                message: "",
              };
            });
          }
        }
      })
      .catch(err => {
        errorCallback();
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
        console.log(err);
      });
  };

  const successCallback = msg => {
    setOpenEdit(false);
    setInfoModal(prevState => {
      return { ...prevState, open: true, message: msg || "Guardado con éxito" };
    });
  };

  const errorCallback = msg => {
    setInfoModal(prevState => {
      return { ...prevState, open: true, message: msg || "Ocurrió un error" };
    });
  };

  const successResetEmail = () => {
    setInfoModal(prevState => {
      return { ...prevState, open: true, message: "Email enviado con éxito" };
    });
  };

  const handleOpenMenu = (event, id) => {
    setAnchorEl(prevState => ({ ...prevState, [id]: event.currentTarget }));
  };

  const handleCloseMenu = id => {
    setAnchorEl(prevState => ({ ...prevState, [id]: null }));
  };

  const handleChangeStateUser = async (body, setSubmitting) => {
    setSubmitting(true);
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "",
      };
    });
    try {
      await changeState(body);
      updateUsersTable(() => {
        table.destroy();
        createUsersTable();
        setSubmitting(false);
        setChangeStateModal(false);
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
        setInfoModal(prevState => {
          return {
            ...prevState,
            open: true,
            message: "Estado modificado con éxito",
          };
        });
      });
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: false,
          message: "",
        };
      });
      setInfoModal(prevState => {
        return {
          ...prevState,
          open: true,
          message: "Ocurrió un error al cambiar el estado",
        };
      });
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Panel de control de usuarios - Xiclos</title>
        <meta
          name="description"
          content="Administrar usuarios de la aplicación"
        />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Administración de usuarios"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <div className="panel-control-users">
        <ModalMui open={deleteModal} handleClose={() => {}} maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography variant="h6" style={{ color: "black" }}>
              Usuario eliminado con éxito
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => window.location.reload(false)}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>

        <ModalMui open={loadingModal.open} handleClose={() => {}} maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography variant="h6" style={{ color: "black" }}>
              {loadingModal.message}
            </Typography>
          </Box>
          {!loadingModal.message && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
                marginBottom: 20,
              }}>
              <Typography variant="h5" style={{ color: "black" }}>
                Cargando...
              </Typography>
            </Box>
          )}
        </ModalMui>

        <ModalMui
          close
          open={openEdit}
          handleClose={handleCloseModalEdit}
          title={"Editar usuario"}>
          <Backdrop
            open={loadingBackDrop}
            style={{ color: "#ffff", zIndex: 1000 }}>
            <CircularProgress color="primary" />
          </Backdrop>
          <EditUserContent
            user={selectedUser}
            subscriptions={subscriptions}
            onClose={handleCloseModalEdit}
            handleEditUser={handleEditUser}
            recoverPasswordEmail={recoverPasswordEmail}
          />
        </ModalMui>

        <ModalMui
          close
          open={changeStateModal}
          handleClose={handleCloseModalChangeStateUser}
          title={"Editar estado de usuario"}>
          <Backdrop
            open={loadingBackDrop}
            style={{ color: "#ffff", zIndex: 1000 }}>
            <CircularProgress color="primary" />
          </Backdrop>
          <ChangeStateUserContent
            user={selectedUser}
            onClose={handleCloseModalChangeStateUser}
            onSubmit={handleChangeStateUser}
            setInfoModal={setInfoModal}
            setLoadingModal={setLoadingModal}
          />
        </ModalMui>

        <ModalMui open={infoModal.open} handleClose={() => {}} maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography variant="h6" style={{ color: "black" }}>
              {infoModal.message}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                setInfoModal(prevState => {
                  return { ...prevState, open: false, message: false };
                });
              }}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>

        {/* Modal alter user sub */}

        <ModalMui
          open={modalAlterSub}
          handleClose={() => setModalAlterSub(false)}
          maxWidth="sm">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            {!selectedUser ? (
              <CircularProgress color="primary" />
            ) : selectedUser.us_suscription_status === "inactive" ? (
              <Typography
                variant="h6"
                style={{ color: "black", textAlign: "center" }}>
                Esto intentara activar la suscripción mercadopago/paypal y
                pasara el estado del usuario a activo dentro de la pagina, este
                proceso puede fallar dependiendo el estado actual de la
                suscripción. Ejemplo: Que la suscripción de mercadopago este
                cancelada, imposibilita la edición de la misma.
                <div style={{ paddingBottom: 40 }} />
                Esta seguro que desea continuar?
              </Typography>
            ) : (
              <Typography
                variant="h6"
                style={{ color: "black", textAlign: "center" }}>
                Esto pausara la suscripción de mercadopago/paypal y pasara el
                estado del usuario a inactivo dentro de la pagina.
                <div style={{ paddingBottom: 40 }} />
                Esta seguro que desea continuar?
              </Typography>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              style={{ marginRight: "5px" }}
              color="primary"
              disableElevation
              onClick={() => {
                setModalAlterSub(false);
              }}>
              Cancelar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleAlterSubscription}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>

        <ModalMui
          open={confirmationModal.open}
          handleClose={() =>
            setConfirmationModal(prevState => {
              return {
                ...prevState,
                open: false,
                message: "",
              };
            })
          }
          maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography
              variant="h6"
              style={{ color: "black", textAlign: "center" }}>
              {confirmationModal.message}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              style={{ marginRight: "5px" }}
              color="primary"
              disableElevation
              onClick={() =>
                setConfirmationModal(prevState => {
                  return {
                    ...prevState,
                    open: false,
                    message: "",
                  };
                })
              }>
              Cancelar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleDeleteUser}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>
        <ModalMui open={infoModal.open} handleClose={() => {}} maxWidth="xs">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography variant="h6" style={{ color: "black" }}>
              {infoModal.message}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                setInfoModal(prevState => {
                  return { ...prevState, open: false, message: false };
                });
              }}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>
        <ModalMui
          open={changeSubModal}
          handleClose={() => setChangeSubModal(false)}
          maxWidth="xs"
          title={"Editar suscripción"}
          close>
          <Formik
            initialValues={{
              subscriptionValue: selectedUser?.subscriptions?.map(
                sub => sub.su_id,
              ),
            }}>
            {({ errors, handleChange, values }) => (
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.subscriptionValue ? true : false}>
                    <InputLabel id="subscription-select">
                      Membresía del usuario
                    </InputLabel>
                    <Select
                      labelId="subscription-select"
                      multiple
                      value={values.subscriptionValue}
                      onChange={handleChange("subscriptionValue")}
                      input={<OutlinedInput label="Membresía del usuario" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getcontentanchorel: null,
                      }}
                      renderValue={selected => (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}>
                          {selected.map((subId, i) => (
                            <Chip
                              key={i}
                              label={
                                subscriptions.find(sub => sub.su_id === subId)
                                  ?.su_name
                              }
                            />
                          ))}
                        </Box>
                      )}>
                      {subscriptions.map(sub => (
                        <MenuItem key={sub.su_id} value={sub.su_id}>
                          <Checkbox
                            checked={
                              values.subscriptionValue.indexOf(sub.su_id) > -1
                            }
                          />
                          <ListItemText primary={sub.su_name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.subscriptionValue}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                    marginBottom: 20,
                  }}>
                  <Button
                    style={{ marginRight: "5px" }}
                    color="primary"
                    disableElevation
                    onClick={() => {
                      setChangeSubModal(false);
                    }}>
                    Cancelar
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      handleChangeSubscription(
                        values.subscriptionValue,
                        selectedUser?.us_id,
                      );
                    }}>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </ModalMui>
        {/* Modal change rol */}
        <ModalMui
          open={changeRolModal}
          handleClose={() => setChangeRolModal(false)}
          maxWidth="xs"
          title={"Editar roles"}
          close>
          <Formik
            initialValues={{
              rolesValues: selectedUser?.roles?.map(rol => rol.rol_id),
            }}>
            {({ errors, handleChange, values }) => (
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.rolesValues ? true : false}>
                    <InputLabel id="subscription-select">Roles</InputLabel>
                    <Select
                      labelId="subscription-select"
                      multiple
                      value={values.rolesValues}
                      onChange={handleChange("rolesValues")}
                      input={<OutlinedInput label="Roles del usuario" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getcontentanchorel: null,
                      }}
                      renderValue={selected => (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}>
                          {selected.map((rolID, i) => (
                            <Chip
                              key={i}
                              label={
                                roles.find(rol => rol.rol_id === rolID)
                                  ?.rol_name
                              }
                            />
                          ))}
                        </Box>
                      )}>
                      {roles.map(rol => (
                        <MenuItem key={rol.rol_id} value={rol.rol_id}>
                          <Checkbox
                            checked={
                              values.rolesValues.indexOf(rol.rol_id) > -1
                            }
                          />
                          <ListItemText primary={rol.rol_name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.rolesValues}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                    marginBottom: 20,
                  }}>
                  <Button
                    style={{ marginRight: "5px" }}
                    color="primary"
                    disableElevation
                    onClick={() => {
                      setChangeRolModal(false);
                    }}>
                    Cancelar
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      handleChangeRoles(
                        values.rolesValues,
                        selectedUser?.us_id,
                      );
                    }}>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </ModalMui>

        <Grid container justifyContent="center" className="paper-container">
          <Grid item xs={12} lg={12} xl={11}>
            <Paper className="paper-content">
              <table
                id="user-table"
                className="table table-bordered table-hover display nowrap"
                cellSpacing="0"
                width="100%">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col">#</th>
                    <th scope="col">ACCIONES</th>
                    <th scope="col">NOMBRE Y APELLIDO</th>
                    <th scope="col">MAIL</th>
                    <th scope="col">TIPO DE MEMBRESÍA</th>
                    <th scope="col">ESTADO</th>
                    <th scope="col">ROLES</th>
                    <th scope="col">ESTADO DE PAGO</th>
                    <th scope="col">FECHA CREACIÓN</th>
                    <th scope="col">FINALIZA</th>
                    <th scope="col">PAÍS</th>
                    <th scope="col">CIUDAD</th>
                    <th scope="col">PROVINCIA</th>
                    <th scope="col">CÓDIGO POSTAL</th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  {users.length !== 0
                    ? users.map(user => {
                        return (
                          <tr key={user.us_id}>
                            <td></td>
                            <td id="id" className={`id-${user.us_id}`}></td>
                            <td>
                              <Grid>
                                <IconButton
                                  size="small"
                                  style={{
                                    color: theme.palette.info.main,
                                  }}
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setOpenEdit(true);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  style={{
                                    color: theme.palette.error.main,
                                  }}
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setConfirmationModal(prevState => {
                                      return {
                                        ...prevState,
                                        open: true,
                                        message: `¿Esta seguro que desea eliminar el usuario: ${user.us_name} ${user.us_lastname}?`,
                                      };
                                    });
                                  }}>
                                  <DeleteForeverIcon />
                                </IconButton>
                                {anchorEl && (
                                  <Fragment>
                                    <IconButton
                                      size="small"
                                      onClick={e =>
                                        handleOpenMenu(e, user.us_id)
                                      }>
                                      <MoreVertIcon />
                                    </IconButton>
                                    <MenuPanelControlUsers
                                      anchorEl={anchorEl[user.us_id]}
                                      user={user}
                                      onClose={() =>
                                        handleCloseMenu(user.us_id)
                                      }
                                      actionMembresia={() => {
                                        handleCloseMenu(user.us_id);
                                        setSelectedUser(user);
                                        setChangeSubModal(true);
                                      }}
                                      actionRol={() => {
                                        handleCloseMenu(user.us_id);
                                        setSelectedUser(user);
                                        setChangeRolModal(true);
                                      }}
                                      actionChangeStateUser={() => {
                                        handleCloseMenu(user.us_id);
                                        setSelectedUser(user);
                                        setChangeStateModal(true);
                                      }}
                                      actionAlterSub={type => {
                                        handleCloseMenu(user.us_id);
                                        setSelectedUser(user);
                                        setAlterType(type);
                                        setModalAlterSub(true);
                                      }}
                                    />
                                  </Fragment>
                                )}
                              </Grid>
                            </td>
                            <td id="name">
                              <p id="nombre-completo">
                                {user.us_name} {user.us_lastname}
                              </p>
                            </td>
                            <td id="email">{user.us_email}</td>
                            <td
                              className="dropdown"
                              id="combo-membresia"
                              onClick={() => {
                                setSelectedUser(user);
                                setChangeSubModal(true);
                              }}>
                              {user.subscriptions
                                .sort((a, b) =>
                                  a.su_name.localeCompare(b.su_name),
                                )
                                .map((sub, i, arr) => (
                                  <Fragment key={sub.su_id}>
                                    {sub.su_name}
                                    {i === arr.length - 1 ? "" : ","}
                                    <br />
                                  </Fragment>
                                ))}
                            </td>
                            <td id="estado-subscripcion">
                              {subscriptionStateData(user)}
                            </td>
                            <td
                              className="dropdown"
                              onClick={() => {
                                setSelectedUser(user);
                                setChangeRolModal(true);
                              }}>
                              {user.roles.length > 0
                                ? user.roles.map((rol, i, arr) => (
                                    <Fragment key={rol.rol_id}>
                                      {rol.rol_name.toUpperCase()}
                                      {i === arr.length - 1 ? "" : ","}
                                      <br />
                                    </Fragment>
                                  ))
                                : "Usuario"}
                            </td>
                            <td id="estado-pago">{billingStateData(user)}</td>
                            <td id="fecha-creacion">
                              {moment(user.us_created_at).format("YYYY-MM-DD")}
                            </td>
                            <td id="fecha-fin-subscripcion">
                              {user.us_exp_date
                                ? moment(user.us_exp_date).format("DD/MM/YYYY")
                                : "NUNCA"}
                            </td>
                            <td id="pais">{user.us_billing.bi_country}</td>
                            <td id="ciudad">{user.us_billing.bi_city}</td>
                            <td>{user.us_billing?.bi_state}</td>
                            <td>{user.us_billing?.bi_zip_code}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default PanelControlUsers;
