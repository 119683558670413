import { API } from "./APIConnector";

export const getNotifications = async (
  type,
  email = null,
  title = null,
  page = 1,
  size = 20,
) => {
  let url = `${API}/notifications?type=${type}`;
  if (email) {
    url += `&email=${email}`;
  }
  if (page) {
    url += `&page=${page}`;
  }
  if (size) {
    url += `&size=${size}`;
  }
  if (title) {
    url += `&title=${title}`;
  }
  let resp = await fetch(url, {
    method: "GET",
    Authorization: localStorage.getItem("Authorization"),
    U: localStorage.getItem("U"),
  });
  let data = await resp.json();
  return data;
};
