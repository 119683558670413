export const LARGE_DEVICE = "LARGE_DEVICE";
export const SMALL_DEVICE = "SMALL_DEVICE";

export const PAYMENT_ACCEPTED = "accepted";
export const PAYMENT_PENDING = "pending";
export const PAYMENT_REJECTED = "rejected";

export const STATUS_USER_ACTIVE = "active";
export const STATUS_USER_INACTIVE = "inactive";
export const STATUS_USER_PENDING = "pending";
export const STATUS_USER_FREE = "free";

export const SUBSCRIPTIONS = {
  general: "GENERAL",
  exclusivo: "EXCLUSIVOALUMNOS01",
  exclusivoAntiguo: "EXCLUSIVOALUMNOS01ANTIGUO",
  menosveinte: "MENOSVEINTE",
  promocic: "PROMOCIC",
  promomacabi: "PROMOMACABI",
  video24: "VIDEO24",
};

export const ROLES = {
  usuario: "usuario",
  administrador: "administrador",
  seo: "seo",
};

export const ASPECT_RATIO_POSTER = 9 / 14;
